import React from 'react';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { Box, Button, Link, ListItem, Title } from './styledComponents';
interface props {
  item: any;
  handleNavbarClose: () => void;
}

export const NavbarItem = ({ item, handleNavbarClose }: props) => {
  const { highlight, link, icon: Icon, title } = item;
  let style = {};
  if (highlight) {
    style = {
      color: '#139d45',
      fontWeight: 'bold'
    };
  }

  return (
    <ListItem>
      {item.hash ? (
        <Button onClick={() => handleNavbarClose()}>
          <Link exact to={{ pathname: link, hash: '#my-ref' }} activeStyle={{ color: '#5850ec', fontWeight: 500 }}>
            <Box>{Icon && <FontAwesomeIcon size={'lg'} icon={Icon} style={style} />}</Box>
            <Title style={style}>{title}</Title>
          </Link>
        </Button>
      ) : (
        <Button onClick={() => handleNavbarClose()}>
          <Link exact activeStyle={{ color: '#5850ec', fontWeight: 500 }} to={link}>
            <Box>{Icon && <FontAwesomeIcon size={'lg'} icon={Icon} style={style} />}</Box>
            <Title style={style}>{title}</Title>
          </Link>
        </Button>
      )}
    </ListItem>
  );
};
