import { IObservableArray, observable } from 'mobx';
import SaleListingModel from 'src/models/SalesListings/saleListingModel';
import SaleListService from 'src/services/Auction/SaleListService';



class SaleListStore {
  
  @observable
  public auctionsModels: Array<SaleListingModel>;

  private registeredCallbacks: Array<(auctionModels: Array<SaleListingModel>) => void>;

  public async registerForUpdate(callBack: (auctionModels: Array<SaleListingModel>) => void) {
    const service = new SaleListService();
    this.registeredCallbacks.push(callBack);
    const response = await service.getSaleList();
    this.auctionsModels = response.data;
    callBack(this.auctionsModels);
    return this.registeredCallbacks.indexOf(callBack);
  }

  public deregisterForUpdate(index: number) {
    this.registeredCallbacks[index] = null;
  }

  constructor() {
    this.auctionsModels = new Array<SaleListingModel>() as IObservableArray;
    this.registeredCallbacks = [];

    setInterval(async () => {
      const service = new SaleListService();
      this.auctionsModels.length = 0;
      const response = await service.getSaleList();
      this.auctionsModels = response.data;
      this.registeredCallbacks.forEach((value) => {
        if (value !== null) {
          value(this.auctionsModels);
        }
      });
    }, 60000);
  }
}

export default SaleListStore;
