
import React from 'react';
import AuctionType from 'src/enums/sales/AuctionType';
import SaleListingModel from 'src/models/SalesListings/saleListingModel';
import { useUserStore } from 'src/stores/useMobxStores';
import { observer } from 'mobx-react-lite';
import LegacySiteLink from '../base/LegacySiteLink';
import { auctionColors } from 'src/constants/themes';
import { InfoButton } from 'src/containers/ActiveAuctions/components/styledComponents';
import { Link } from '@fluentui/react/lib/Link';

interface IAuctionButtons {
  /** Model: SaleListingModel */
  sale: SaleListingModel;
  /** Function to open Login modal */
  onLogInModal?: () => void;
  /** Toggle for timed auctions */
}

const ButtonRedirect = (props) => {
  switch (props.saleType) {
    case AuctionType.Event:
    case AuctionType.None:
      return (
        <LegacySiteLink oldUrl={`/auctionnew/event?auctionId=${props.auctionId}`} target=''>
          <InfoButton color={auctionColors.upCommingSale} darkColor={auctionColors.upCommingSale}>
            Enter Live Event 
          </InfoButton>
        </LegacySiteLink>
      );
    default:
      return (
        <LegacySiteLink oldUrl={`/auctionnew/?isBuyer=true&auctionId=${props.auctionId}`} target=''>
          <InfoButton color={auctionColors.upCommingSale} darkColor={auctionColors.upCommingSaleDark}>
            Enter Live Auction 
          </InfoButton>
        </LegacySiteLink>
      );
  }
};

const AuctionButtons = ({ sale, onLogInModal }: IAuctionButtons) => {
  const userStore = useUserStore();
  
  return sale.auctionType === AuctionType.Timed ? (
    <Link underline={false} href={sale.externalSaleLink}>
      <InfoButton color={auctionColors.upCommingTimed} darkColor={auctionColors.upCommingTimedDark}>
        Enter Timed Auction
      </InfoButton>
    </Link>
  ) : userStore.IsAuthenticated ? (
    <ButtonRedirect saleType={sale.auctionType} auctionId={sale.auctionId} externalLink={sale.externalSaleLink} />
  ) : (
    <InfoButton onClick={onLogInModal} color={auctionColors.upCommingSale} darkColor={auctionColors.upCommingSaleDark}>
      {sale.auctionType === AuctionType.None || sale.auctionType === AuctionType.Event
        ? 'Enter Live Event'
        : 'Enter Live Auction'}
    </InfoButton>
  );
};

export default observer(AuctionButtons);

