import { observer } from 'mobx-react-lite';
import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Header from 'src/components/common/Header/Header';
import AdminNavbar from 'src/components/common/Navbar/AdminNavbar';
import { AdminContent, Root, AdminNav } from './styledComponents';

const AdminLayout: React.FC = (props) => {
  return (
    <Root>
      <AdminNav>
        <AdminNavbar />
      </AdminNav>
      <Header />
      <AdminContent>{props.children}</AdminContent>
    </Root>
  );
};

export default observer(AdminLayout);
