import { makeObservable, observable } from 'mobx';
import FormModel from 'src/models/forms/FormModel';

export default class PureBreedModel extends FormModel {
  constructor() {
    super();
    makeObservable(this);
  }
  @observable
  name: string;
  @observable
  registrationNumber: string;
  @observable
  location: string;
  @observable
  birthDate: string;
  @observable
  dam: string;
  @observable
  sire: string;
  @observable
  pureBredComments: string;
  @observable
  sgSire: string;
  @observable
  sgDam: string;
  @observable
  dgSire: string;
  @observable
  dgDam: string;
  @observable
  epdname1: string;
  @observable
  epdname2: string;
  @observable
  epdname3: string;
  @observable
  epdname4: string;
  @observable
  epdname5: string;
  @observable
  epdname6: string;
  @observable
  epdname7: string;
  @observable
  epdname8: string;
  @observable
  epdname9: string;
  @observable
  epdname10: string;
  @observable
  epdvalue2: string;
  @observable
  epdvalue3: string;
  @observable
  epdvalue1: string;
  @observable
  epdvalue4: string;
  @observable
  epdvalue5: string;
  @observable
  epdvalue6: string;
  @observable
  epdvalue7: string;
  @observable
  epdvalue8: string;
  @observable
  epdvalue9: string;
  @observable
  epdvalue10: string;
}
