import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'mobx-react-router';
import * as React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { useRouterStore } from '../stores/useMobxStores';
import { observer } from 'mobx-react-lite';
import { Streaming, MainSite } from './routeList';
import 'react-toastify/dist/ReactToastify.css';
import LoadingComponent from 'src/components/common/loaders/LoadingComponent';
import { VideoIntegrationLoader } from 'src/components/common/loaders/routeLoader';


// const useStyles = makeStyles((theme: any) => {
//   console.log('> routes: theme', theme.palette.background.dark);
//   return createStyles({
//     '@global': {
//       body: {
//         backgroundColor: theme.palette.background.dark
//       }
//     }
//   });
// });

const Routes = () => {
  const  routerStore  = useRouterStore();
  const browserHistory = createBrowserHistory();
  const history = syncHistoryWithStore(browserHistory, routerStore);

  return (
    <Router history={history}>
      <div style={{ position: 'relative' }}>
        <Switch>
          <Route
            path={`/streaming/integration`}
            exact={true}
            component={LoadingComponent.GetLoadingComponent(VideoIntegrationLoader)}
          />
          <Route path={'/Streaming'} component={Streaming} />
          <Route path={'/'} component={MainSite} />
        </Switch>
      </div>
    </Router>
  );
};

export default observer(Routes);
