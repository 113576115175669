import LotDetailModel from 'src/models/common/AuctionLot/AuctionLotDetailModel';
import AuctionLotListModel from 'src/models/common/AuctionLot/AuctionLotListModel';
import ServiceBase from '../Common/ServiceBase';




class AuctionService extends ServiceBase {
  public getAuctionLotListing = async (auctionId: number) => {
    return await this.get<AuctionLotListModel>(`/AuctionLot/${auctionId}`);
  };
  public getAuctionLot = async (lotId: number) => {
    return await this.get<LotDetailModel>(`/AuctionLot/checkLot/${lotId}`);
  };
  public getAuctionLotImages = async (lotId: number) => {
    return await this.get<Array<string>>(`/LotImages/${lotId}`);
  };
}

export default AuctionService;
