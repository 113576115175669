
import { device } from 'src/constants/themes';
import styled from 'src/utils/styled/styled';


export const Logo = styled.img`
    height: 80px;
    width: 250px;
    margin-bottom: -5px;
`;

export const AdminLogo = styled(Logo)`
  width: 200px;
`
  
export const DrawerContainer = styled.div`
  overflow: hidden;
  background-color: white;
  width: 256px;
  height: 100vh;
  display: grid;
  grid-template-rows: min-content min-content 1fr;
`;

export const NavBox = styled.div`
  padding: 16px;
  display: flex;
  max-height: 100vh;
  flex-direction: column;
  padding-bottom: 0px;
  justify-content: flex-start;
`;

export const Divider = styled.div`
  border-top: 1px solid lightgrey;
`;

export const ButtonContainer = styled.div`
  height: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const CloseButton = styled.button`
  background-color: white;
  text-transform: uppercase;
  height: 36px;
  width: max-content;
  color: black;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  padding: 6px 16px;
  border: 0px;
  border-radius: 4px;
  
`;

export const Drawer = styled.div`
  width: 256px;
  flex-shrink: 0;
  top: 0;
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  outline: 0;
  z-index: 100000;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  @media ${device.desktop} {
    width: 0px;
  }
`;

export const AdminDrawerContainer = styled(DrawerContainer)`
  width: 200px;
`;

export const AdminDrawer = styled(Drawer)`
  width: 200px;
`;

export const AdminNavBox = styled(NavBox)`
 padding: 5px;
`