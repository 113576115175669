import React from 'react';
import { formatDate, formatTime } from 'src/common/formatters';
import { FlexContainer } from 'src/components/base/container';
import SaleListingModel from 'src/models/SalesListings/saleListingModel';
import { observer } from 'mobx-react-lite';
import { useUtilityStore, useUserStore } from 'src/stores/useMobxStores';
import { siteUrls } from 'src/constants/siteUrls';
import AuctionType from 'src/enums/sales/AuctionType';
import { AuctionGradientPicker } from 'src/common/AuctionColorPicker';
import {
  BidderLink,
  CustomerTextNoImage,
  DateContainer,
  DateType,
  ImageContainer,
  LinkBox,
  TextBox,
  TypeContainer,
  Image
} from '../styledComponents';
import {  StyledLink } from 'src/components/base/styledComponents';
import { HiddenMobileDown } from 'src/components/base/hidden';
interface IUpcomingAuctionDisplay {
  /** Model to passed in: SaleListingModel */
  sale?: SaleListingModel;
  /** Link to be passed in to direct to sale page */
  link?: string;
  setIsImage?: (value: boolean) => void;
  isImage?: boolean;
  justify?: boolean;
  direction?: boolean;
}

export function ImageDisplay({ sale, setIsImage }: IUpcomingAuctionDisplay) {
  return (
    <ImageContainer>
      <Image
        alt='promo'
        height={'auto'}
        width={'100%'}
        src={siteUrls.GetDlmsPromoImageUrl(sale.promotionalImage)}
        onError={() => setIsImage(false)}
      />
    </ImageContainer>
  );
}

export const BidderRegistrationNoImage = observer(({ sale, isImage }: IUpcomingAuctionDisplay) => {
  if (isImage) return <></>;
  return (
    <HiddenMobileDown>
      <CustomerTextNoImage>
        <CustomerText sale={sale} />
      </CustomerTextNoImage>
    </HiddenMobileDown>
  );
});

export function DateTypeDisplay({ sale, link }: IUpcomingAuctionDisplay) {
  return (
    <FlexContainer>
      <DateType>
        <DateContainer>
          <LinkBox href={link} underline>
            {formatDate(sale.auctionDate)} {formatTime(sale.auctionDate)} {sale.timeZone.replace('S', '')}
          </LinkBox>
        </DateContainer>
        <LinkBox href={link}>
          <TypeContainer color={AuctionGradientPicker(sale.auctionType)}>{sale.auctionTypeDisplay}</TypeContainer>
        </LinkBox>
      </DateType>
    </FlexContainer>
  );
}

export function CustomerText({ sale, justify, direction }: IUpcomingAuctionDisplay) {
    const userStore = useUserStore();
    const utilityStore = useUtilityStore();
  const flexEnd = justify ? 'flex-end' : 'flex-start';
  const flexDirection = direction ? 'column' : 'row';
  return (
    <FlexContainer justifyContent={flexEnd} columnGap={'10px'}>
      <TextBox direction={flexDirection}>
        {userStore.isLoggedIn && sale.auctionType === AuctionType.Purebred && (
          <BidderLink onClick={utilityStore.closeModal} to={`/Info/BuyerApproval`}>
            Buyer Approval
          </BidderLink>
        )}
        {sale.customerLinkText && (
          <StyledLink target='_blank' href={sale.customerLinkUrl}>
            {sale.customerLinkText}
          </StyledLink>
        )}

        {sale.saleCatalogueText && (
          <StyledLink target='_blank' href={sale.saleCatalogueUrl}>
            {sale.saleCatalogueText}
          </StyledLink>
        )}
        {sale.videoText && (
          <StyledLink target='_blank' href={sale.videoUrl}>
            {sale.videoText}
          </StyledLink>
        )}
        {sale.infoDocumentText && (
          <StyledLink target='_blank' href={`https://dlms.ca/Media/Document/${sale.infoDocument}`}>
            {sale.infoDocumentText}
          </StyledLink>
        )}
      </TextBox>
    </FlexContainer>
  );
}
