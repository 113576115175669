enum AuctionStatus {
  Unknown = 0,
  Cancelled = 1,
  Selling = 2,
  Deleted = 3,
  Consigning = 4,
  Sold = 5
}

export default AuctionStatus;
