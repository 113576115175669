import { extend, isObject, mapValues } from 'lodash';

export const objectMapper = (source: any, destination: any, ignoreProps: Array<string> = []) => {
  mapValues(source, (initialValue, key) => {
    if (ignoreProps.includes(key)) {
      return;
    }
    if (Array.isArray(initialValue)) {
      const destinationArray = destination[key] || [];
      destinationArray.length = 0;
      for (const i in initialValue) {
        const value = initialValue[i];
        if (isObject(value)) {
          const newObject = {};
          extend(newObject, initialValue[i]);
          destinationArray.push(newObject);
        } else {
          destinationArray.push(initialValue[i]);
        }
      }
    } else if (isObject(initialValue)) {
      objectMapper(initialValue, destination[key]);
    } else {
      destination[key] = initialValue;
    }
  });
};
