import SaleListingModel from 'src/models/SalesListings/saleListingModel';
import ServiceBase from '../Common/ServiceBase';

class SaleListService extends ServiceBase {
  public getSaleList = async () => {
    return await this.get<SaleListingModel[]>(`/SaleListing`);
  };

}

export default SaleListService;
