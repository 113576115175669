import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Card } from 'src/components/base/container';
import SaleListingModel from 'src/models/SalesListings/saleListingModel';
import AuctionType from 'src/enums/sales/AuctionType';
import UpcomingAuctionMobile from './UpcomingAuctionMobile';
import { siteUrls } from 'src/constants/siteUrls';
import {
  HiddenMobileUp,
  HiddenTabletDown,
  HiddenTabletUpMobileDown
} from 'src/components/base/hidden';
import UpcomingAuctionTablet from './UpcomingAuctionTablet';
import UpcomingAuctionDesktop from './UpcomingAuctionDesktop';


interface IUpcomingAuctionDisplay {
  sale: SaleListingModel;
}

function UpcomingAuctionDisplay({ sale }: IUpcomingAuctionDisplay) {
  const link =
    sale.auctionType === AuctionType.Timed ? sale.externalSaleLink : siteUrls.GetAuctionLotListUrl(sale.auctionId);
  const [isImage, setIsImage] = useState<boolean>(false);


  React.useEffect(() => {
    setIsImage(!!sale.promotionalImage);
  }, []);
  return (
    <Card>
      <HiddenTabletDown>
        <UpcomingAuctionDesktop sale={sale} link={link} setIsImage={setIsImage} isImage={isImage} />
      </HiddenTabletDown>
      <HiddenTabletUpMobileDown>
        <UpcomingAuctionTablet sale={sale} link={link} setIsImage={setIsImage} isImage={isImage} />
      </HiddenTabletUpMobileDown>
      <HiddenMobileUp>
        <UpcomingAuctionMobile sale={sale} setIsImage={setIsImage} isImage={isImage} />
      </HiddenMobileUp>
    </Card>
  );
}

export default observer(UpcomingAuctionDisplay);
