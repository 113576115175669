import { runInAction } from 'mobx';
import ResponseModel from 'src/models/account/ResponseModel';
import { CompanyContactModel } from 'src/models/admin/CompanyContactModel';
import { SaleTypeModel } from 'src/models/admin/SaleTypeModel';
import AdminStore from 'src/stores/AdminStore';



import ServiceBase from '../Common/ServiceBase';

class SaleTypeService extends ServiceBase {
  public getSaleTypeInfo = async (store: AdminStore) => {
    store.loading = true;
    const response = await this.get<Array<SaleTypeModel>>(`/saletype`);
    runInAction(() => {
      const list = new Array<SaleTypeModel>();
      response.data.map((value) => {
        const company = new SaleTypeModel();
        company.SetData(value);
        list.push(value);
      });
      store.saleTypeList.clear();
      store.saleTypeList.replace(list);
      store.loading = false;
    });
  };

  public updateSaleType = async (contact: SaleTypeModel) => {
    const response = await this.put<ResponseModel>(`/saletype`, contact);
    return response;
  };
  public createSaleType = async (contact: SaleTypeModel) => {
    return await this.post<ResponseModel>(`/saletype`, contact);
  };
}

export default SaleTypeService;
