import { runInAction } from 'mobx';
import ResponseModel from 'src/models/account/ResponseModel';
import { AudioStreamModel } from 'src/models/admin/AudioStreamModel';
import { LiveStreamModel } from 'src/models/admin/LiveStreamModel';

import { SaleTypeModel } from 'src/models/admin/SaleTypeModel';
import AdminStore from 'src/stores/AdminStore';



import ServiceBase from '../Common/ServiceBase';

class AuctionInformationService extends ServiceBase {
  public getHostMarkets = async (store: AdminStore) => {
    store.loading = true;
    const response = await this.get<Array<LiveStreamModel>>(`/auctioninformation/livestream`);
    runInAction(() => {
      const list = new Array<LiveStreamModel>();
      response.data.map((value) => {
        const company = new LiveStreamModel();
        company.SetData(value);
        list.push(value);
      });
      store.liveStreamList.clear();
      store.liveStreamList.replace(list);
      store.loading = false;
    });
  };
  public getAudioStreams = async (store: AdminStore) => {
    store.loading = true;
    const response = await this.get<Array<AudioStreamModel>>(`/auctioninformation/audiostream`);
    runInAction(() => {
      const list = new Array<AudioStreamModel>();
      response.data.map((value) => {
        const company = new AudioStreamModel();
        company.SetData(value);
        list.push(value);
      });
      store.audioStreamList.clear();
      store.audioStreamList.replace(list);
      store.loading = false;
    });
  };


}

export default AuctionInformationService;
