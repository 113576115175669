import { LegacyApiUrls } from 'src/constants/legacyApiUrls';
import { ILoginModel } from 'src/interfaces/Users/IUserModels';
import CheckLoginModel from 'src/models/account/CheckLoginModel';
import ProfileModel from 'src/models/account/ProfileModel';
import ServiceBase from '../Common/ServiceBase';
 
class LoginService extends ServiceBase {
  public login = async (model: ILoginModel) => {
    return await this.post<CheckLoginModel>(`/login`, model);
  };
  public legacyLogin = async (model: ILoginModel) => {
    return await this.post<ILoginModel>(LegacyApiUrls.Login, model);
  };
  public getVerification = async (EmailVerificationToken: string) => {
    return await this.get(`/login/verification/${EmailVerificationToken}`);
  }
  public getLoggedIn = async () => {
    return await this.get<ProfileModel>(`/login`);
  };
  public legacyLoginCheck = async () => {
    return await this.get<boolean>(LegacyApiUrls.LoginCheck);
  };
  public logout = async () => {
    return await this.delete<void>(`/login`);
  };
}

export default LoginService;
