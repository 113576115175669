import { useContext } from 'react';
import SettingsContext from './settingsContext';

const useSettings = () => {
  const context = useContext(SettingsContext);

  return context;
};

export default useSettings;
