export class ApiUrls {
  public static GetBaseUrl(): string {
    if (window.location.hostname === 'localhost') {
      return 'https://localhost:5001';
    }
    return `https://${window.location.host}`;
  }



  public static LiveStreams = `${ApiUrls.GetBaseUrl()}/LiveStreams`;
  public static SaleList = `${ApiUrls.GetBaseUrl()}/SaleListing`;
  public static ExtendedSaleList = `${ApiUrls.GetBaseUrl()}/ExtendedSaleListing`;
  public static MarketReportList = `${ApiUrls.GetBaseUrl()}/MarketReportListing`;
  public static BuyerApproval = `${ApiUrls.GetBaseUrl()}/BuyerApproval`;
  public static MarketReportDetail = `${ApiUrls.GetBaseUrl()}/MarketReportDetail`;
  public static RtmpId = `${ApiUrls.GetBaseUrl()}/streamadministration/rtmp`;
  public static StreamAdministration = `${ApiUrls.GetBaseUrl()}/streamadministration`;
  public static ModernLogin = `${ApiUrls.GetBaseUrl()}/login/`;
  public static ModernRegister = `${ApiUrls.GetBaseUrl()}/registration`;
  public static Ping = `${ApiUrls.GetBaseUrl()}/ping`;
}
