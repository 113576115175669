import { auctionColors, auctionGradients } from 'src/constants/themes';
import AuctionType from 'src/enums/sales/AuctionType';


export const AuctionColorPicker = (saleType: AuctionType) => {
  let color = '';
  switch (saleType) {
    case AuctionType.Feeders:
      color = auctionColors.feeders;
      break;
    case AuctionType.Presort:
      color = auctionColors.presort;
      break;
    case AuctionType.Purebred:
      color = auctionColors.purebred;
      break;
    case AuctionType.Event:
      color = auctionColors.event;
      break;
    case AuctionType.Timed:
      color = auctionColors.timed;
      break;
    default:
      color = auctionColors.other;
      break;
  }
  return color;
}

export const ExtendedSalesColorPicker = (saleType: AuctionType) => {
  let color = '';
  switch (saleType) {
    case AuctionType.Feeders:
      color = auctionColors.feeders;
      break;
    case AuctionType.Presort:
      color = auctionColors.presort;
      break;
    case AuctionType.Purebred:
      color = auctionColors.purebredLight;
      break;
    case AuctionType.Event:
      color = auctionColors.event;
      break;
    case AuctionType.Timed:
      color = auctionColors.timed;
      break;
    default:
      color = auctionColors.other;
      break;
  }
  return color;
};


export const AuctionColorPickerDark = (saleType: AuctionType) => {
  let color = '';
  switch (saleType) {
    case AuctionType.Feeders:
      color = auctionColors.feedersDark;
      break;
    case AuctionType.Presort:
      color = auctionColors.presortDark;
      break;
    case AuctionType.Purebred:
      color = auctionColors.purebredDark;
      break;
    case AuctionType.Event:
      color = auctionColors.eventDark;
      break;
    case AuctionType.Timed:
      color = auctionColors.timedDark;
      break;
    default:
      color = auctionColors.otherDark;
      break;
  }
  return color;
}

export const AuctionGradientPicker = (saleType: AuctionType) => {
  let color = '';
  switch (saleType) {
    case AuctionType.Feeders:
      color = auctionGradients.feeders;
      break;
    case AuctionType.Presort:
      color = auctionGradients.presort;
      break;
    case AuctionType.Purebred:
      color = auctionGradients.purebred;
      break;
    case AuctionType.Event:
      color = auctionGradients.event;
      break;
    case AuctionType.Timed:
      color = auctionGradients.timed;
      break;
    default:
      color = auctionGradients.other;
      break;
  }
  return color;
};

