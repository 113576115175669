import { observable, makeObservable } from 'mobx';
import { RegisterOptions } from 'react-hook-form';
import TextBoxCase from '../../enums/form/TextBoxCase';

class InputDefinition {
  public constructor() {
    makeObservable(this);
    this.RegisterOptions = {};
  }

  @observable
  public Name?: string;
  @observable
  public Label?: string;
  @observable
  public HelpText?: string;
  @observable
  public Mask?: string;
  @observable
  public Placeholder?: string;
  @observable
  public TextBoxCase?: TextBoxCase;
  @observable
  public RegisterOptions: RegisterOptions;
}

export default InputDefinition;
