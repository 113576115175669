/**
 *
 * validationMessages
 *
 */
import { isFirstCharacterVowel, transformTemplate } from '../helpers/stringHelpers';


const validationMessages = {
MaxStringLength: "{{propertyName}} cannot be longer than {{value}} characters",
MinStringLength: "{{propertyName}} must be longer than {{value}} characters",
Required: "Please enter {{propertyName}}",
RequiredCheckbox: "Please {{propertyAction}}",
RequiredSelect: "Please select {{propertyName}}",
MinValue: "Minimum value is {{value}}",
MaxValue: "Maximum value is {{value}}",
}

// TODO: This is hard coded to english at this time. We will need to supply an article for each propertyName in french since
// 'a' and 'an' don't directly translate to the masculine and feminine articles 'un' and 'une' in french.
export function getRequiredMessageForTextBox(propertyName?: string) {
  return transformTemplate(validationMessages.Required, {
    propertyName: !!propertyName ? `${isFirstCharacterVowel(propertyName) ? 'an' : 'a'} ${propertyName}` : 'a value'
  });
}

// TODO: This is hard coded to english at this time. We will need to supply an article for each propertyName in french since
// 'a' and 'an' don't directly translate to the masculine and feminine articles 'un' and 'une' in french.
export function getRequiredMessageForSelect(propertyName?: string) {
  return transformTemplate(validationMessages.RequiredSelect, {
    propertyName: !!propertyName ? `${isFirstCharacterVowel(propertyName) ? 'an' : 'a'} ${propertyName}` : 'an item'
  });
}

export function getRequiredMessageForCheckbox(propertyAction: string) {
  return transformTemplate(validationMessages.RequiredCheckbox, { propertyAction });
}

export function getMaxStringLength(propertyName: string, value: number) {
  return transformTemplate(validationMessages.MaxStringLength, { propertyName, value });
}

export function getMinStringLength(propertyName: string, value: number) {
    return transformTemplate(validationMessages.MinStringLength, { propertyName, value });
  }

export function getMinValue(propertyName: string, value: number) {
    return transformTemplate(validationMessages.MinValue, { value });
  }
  
  export function getMaxValue(propertyName: string, value: number) {
    return transformTemplate(validationMessages.MinValue, { value });
  }
  