function isNullOrUndefined(test: any): test is undefined | null {
  return typeof test === 'undefined' || test === null;
}

function isNull(test: any): test is null {
  return test === null;
}

function isUndefined(test: any): test is undefined {
  return typeof test === 'undefined';
}

function isObject(test: any): test is object {
  // workaround for typeof null === 'object' javascript bug
  return !isNull(test) && typeof test === 'object';
}

export { isNullOrUndefined, isNull, isUndefined, isObject };
