import { observable } from 'mobx';
import FormModel from 'src/models/forms/FormModel';


export default class AuctionListDetailModel extends FormModel {
  constructor() {
    super();
  }

  @observable
  sequenceNumber: number;
  @observable
  lotNumber: number;
  @observable
  head: number;
  @observable
  sex: string;
  @observable
  avgWeight: number;
  @observable
  consignorName: string;
  @observable
  location: string;
  @observable
  lotId: number;
  @observable
  penNo: string;
  @observable
  breed: string;
  @observable
  totalWeight: number;
  @observable
  pureBreedName: string;
}
