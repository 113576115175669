import { makeObservable, observable } from 'mobx';
import FormModel from 'src/models/forms/FormModel';

export default class FrameQualityModel extends FormModel {
  constructor(qualityId?: number) {
    super();
    makeObservable(this);
    qualityId && (this.qualityId = qualityId);
    qualityId && (this.large = 0);
    qualityId && (this.medium = 0);
    qualityId && (this.small = 0);
  }

  @observable
  lotId: number;
  @observable
  large: number;
  @observable
  medium: number;
  @observable
  small: number;
  @observable
  qualityId: number;
}
