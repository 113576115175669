import { Link } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { FlexContainer, Image } from 'src/components/base/container';
import { device } from 'src/constants/themes';
import { AdModel } from 'src/models/admin/AdModel';
import AdService from 'src/services/Admin/AdService';
import { stringIsNullOrEmpty } from 'src/utils/helpers/stringHelpers';
import styled from 'src/utils/styled/styled';

interface IMultipleAds {
  /**Position the ad will take, use only these two: 1 = General; 5 = Market Reports */
  position: number;
}

const AdBox = styled.div`
  height: 100px;
  width: auto;
  margin: 10px;
  :nth-of-type(2) {
    @media ${device.mobileM} {
      display: none;
    }
  }
  :nth-of-type(3) {
    @media ${device.tablet} {
      display: none;
    }
  }
`;

const MultipleAds = ({ position }: IMultipleAds) => {
  const [navList, setNavList] = useState<AdModel[] | null>(null);

  const getGeneralAds = async () => {
    const service = new AdService();
    const response = await service.getAds(3, position);
    setNavList(response.data);
  };

  const onAdClick = (ad: AdModel) =>{
    var gtag = (window as any).gtag;
    gtag('event', 'ad-click', {
      'dlms_ad_name': ad.name,
      'dlms_ad_url': ad.url
    });
  }


  useEffect(() => {
    getGeneralAds();
    const interval = setInterval(() => {
      getGeneralAds();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (

    <FlexContainer justifyContent={'space-around'}>
      {navList == null || navList.map == null ? <></> :
        navList.map((value, key) => (
          <AdBox key={key}>
            {!stringIsNullOrEmpty(value.url) ? (
              <Link onClick={()=>{onAdClick(value)}} href={value.url} target={'_blank'}>
                <Image src={`data:image/jpeg;base64,${value.image}`} alt={value.name} />
              </Link>
            ) : (
              <Image onClick={()=>{onAdClick(value)}} src={`data:image/jpeg;base64,${value.image}`} alt={value.name} />
            )}
          </AdBox>
        ))}
    </FlexContainer>

  )

};

export default observer(MultipleAds);
