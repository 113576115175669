import { AdModel } from 'src/models/admin/AdModel';
import ServiceBase from '../Common/ServiceBase';

class AdService extends ServiceBase {
  public getAds = async (count: number, position: number) => {
    return await this.get<Array<AdModel>>(`/ads/${count}/${position}`);
  };
  public upLoadAds = async (adModels: AdModel) => {
    return await this.post<boolean>(`/ads`, adModels);
  };
  public deleteAds = async (id: number) => {
    return await this.delete<Array<void>>(`/ads/${id}`);
  };
}

export default AdService;
