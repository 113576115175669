import { device } from 'src/constants/themes';
import styled from 'src/utils/styled/styled';
import { Link } from 'react-router-dom';
export const StyledLink = styled(Link)`
  font-size: 1rem;
  color: #546e7a;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  &:visited {
    color: #546e7a;
  }
`;
export const StyledList = styled.div`
  font-size: 1rem;
  color: #546e7a;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  &:visited {
    color: #546e7a;
  }
`;

export const MobileIcon = styled.div`
  display: none;
  @media ${device.mobileM} {
    display: inline;
    position: absolute;
    bottom: -8px;
    left: -8px;
  }
`;

export const AccountIcon = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  @media ${device.mobileM} {
    display: inline;
    position: relative;
  }
`;

export const AccountContainer = styled.div`
  display: grid;
  grid-template-columns: auto min-content;
  height: 100%;
  justify-items: end;
  align-items: center;
  position: relative;
  @media ${device.mobileM} {
    padding-left: 0px;
    padding-right: 6px;
  }
  @media ${device.mobileS} {
    padding-right: 12px;
  }
`;

export const WelcomeContainer = styled.div`
  display: grid;
  justify-items: end;
  @media ${device.mobileM} {
    display: none;
  }
`;

export const Login = styled.div`
  text-align: left;
  font-size: .875rem;
  padding: 0;
  margin: 0;
  line-height: 1;
  @media ${device.tablet} {
    font-size: 12px;
  }
`;

export const AccountInformation = styled.h3`
  text-align: right;
  padding: 0px;
  margin-right: 16px;
  margin: 0px;
  line-height: 1;
  padding-top: 2px;
  font-size: .95rem;
  @media ${device.tablet} {
    font-size: 12px;
  }
`;

export const LoginControl = styled.div`
  display: grid;
  grid-template-rows: 1fr 25px;
  background-color: #203260;
  position: relative;
  padding: 5px;
  align-items: end;
`;

export const LoginInputs = styled.div`
  width: 100%;
`;

export const StyledInput = styled.input`
  padding: 2px;
  margin: 2px;
  width: 90%;
`;

export const LoginButton = styled.button`
  background-color: #ccb145;
  color: white;
  border-radius: 0px;
  margin: 5px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

export const LoginLinks = styled.div`
  margin-left: auto;
  margin-right: 8px;
  width: max-content;
  display: grid;
  grid-template-columns: 1fr max- content max-content;
  grid-template-rows: '25px';
  margin-bottom: 0px;
  grid-column-gap: 10px;
  a {
    color: #ccb145;
    font-size: 0.8rem;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ForgotLink = styled.div`
  width: 100%;
  grid-column: 2;
  text-align: end;
`;

export const CreateLink = styled.div`
  grid-column: 3;
  width: max-content;
  span {
    color: #ccb145;
    margin-right: 10px;
  }
`;
