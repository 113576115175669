import { device, siteColors } from 'src/constants/themes';
import Flex, { FlexItem } from 'styled-flex-component';
import styled from '../../utils/styled/styled';

export { Flex, FlexItem };

interface IFlexProps {
  column?: boolean;
  justifyContent?: 'center' | 'flex-end' | 'flex-start' | 'space-between' | 'space-around';
  flexWrap?: 'wrap' | 'wrap-reverse';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline';
  gap?: string;
  columnGap?: string;
  rowGap?: string;
}

interface IPaddedBox {
  marginBottom?: boolean;
}

export const FullWidthFlex = styled(Flex)`
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
`;

export const FlexContainer = styled(Container)<IFlexProps>`
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  justify-content: ${(props) => props.justifyContent ?? 'flex-start'};
  align-items: ${(props) => props.alignItems ?? 'stretch'};
  gap: ${(props) => props.gap ?? null};
  column-gap: ${(props) => props.columnGap ?? null};
  row-gap: ${(props) => props.rowGap ?? null};
  flex-wrap: ${(props) => props.flexWrap ?? 'nowrap'};
`;

export const FlexContainerColumnMarginBottom = styled(FlexContainer)`
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const PaddedBox = styled.div<IPaddedBox>`
  padding: 24px;
  margin-bottom: ${(props) => (props.marginBottom ? '40px' : null)};
  @media ${device.mobileM} {
    padding: 8px;
    padding-top: 24px;
  }
`;
export const LargePaddedBox = styled.div<IPaddedBox>`
  padding: 48px;
  margin-bottom: ${(props) => (props.marginBottom ? '40px' : null)};
  @media ${device.mobileM} {
    padding: 8px;
    padding-top: 48px;
  }
`;

export const BoxHeader = styled.div`
  background: ${siteColors.primary};
  display: flex;
  color: white;
  font-weight: bold;
  padding: 10px;
  padding-left: 15px;
  flex-grow: 1;
  @media ${device.mobileM} {
    font-size: 14px;
  }
`;
interface ICard {
  marginBottom?: string;
  color?: string;
}
export const Card = styled.div<ICard>`
  border-radius: 4px;
  box-shadow: 0 0 1px rgb(63 63 68 / 15%), 0 1px 2px 0 rgb(63 63 68 / 25%);
  margin-bottom: ${(props) => props.marginBottom ?? null};
  background-color: ${(props) => props.color ?? null};
`;

export const DescriptionTextBox = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  @media ${device.mobileM} {
    font-size: 0.8rem;
  }
`;

export const DescriptionSubtitle = styled.div`
  font-size: 1.2146rem;
  margin-bottom: 4px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  margin-top: 4px;
  font-weight: 500;
`;

export const SmallPaddedBox = styled.div`
  margin-bottom: 8px;
  margin-top: 8px;
`;
interface IImage {
  height?: string;
  width?: string;
}
export const Image = styled.img<IImage>`
  height: ${(props) => props.height ?? '100%'};
  width: ${(props) => props.width ?? '100%'};
  object-fit: contain;
`;
