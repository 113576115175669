import { device, siteColors } from 'src/constants/themes';
import styled from 'src/utils/styled/styled';

export const LoadingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
`;

export const LoginButton = styled.button`
  border: none;
  background-color: ${siteColors.primary};
  color: white;
  font-weight: 500;
  font-size: 1rem;
  @media ${device.mobileM} {
    font-size: 0.875rem;
  }
`;

export const BarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  color: white;
  background-color: ${siteColors.primary};

`;

export const MenuBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  min-height: 80px;
  padding: 0px 8px;
`;

export const LoginBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;


export const HeaderName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 200px;
  @media ${device.desktop} {
    padding-left: 0;
  }
`;

export const HeaderH1 = styled.span`
  font-family: 'times new roman';
  margin: 0;
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
  @media ${device.tablet} {
    font-size: 1.5rem;
  }
  @media ${device.mobileM} {
    font-size: 1rem;
  }
  @media ${device.mobileS} {
    font-size: 0.8rem;
  }
`;

export const Grow = styled.span`
  font-family: 'times new roman';
  font-size: 2.25rem;
  font-weight: 500;
  margin: 0;
  @media ${device.tablet} {
    font-size: 1.9rem;
  }
  @media ${device.mobileM} {
    font-size: 1.2rem;
  }
  @media ${device.mobileS} {
    font-size: 0.9rem;
  }
`;