import { FocusZone, IStyleFunctionOrObject, ITextFieldStyleProps, ITextFieldStyles, MaskedTextField, TextField } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import IInputProps from '../common/IInputProps';

export interface IStandardTextBox extends IInputProps {
  showPlaceholderChars?: boolean;
  overridePlaceholderChar?: string;
  min?: number;
  max?: number;
  step?: number;
  autoComplete?: string;
  multiline?: boolean;
  autoAdjustHeight?: boolean;
  borderless?: boolean;
  prefix?: string;
  onChange?: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void;
  onEnter?: () => void;
  styles: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>;
}

const StandardTextBox = (props: IStandardTextBox) => {
  let value = props.value;
  if (props.value === null || props.value === undefined) {
    value = '';
  }

  const inputName = props.overrideName ?? props.name;

  const formState = useFormState();
  const formContext = useFormContext();

  React.useEffect(() => {
    formContext.register(inputName, props.inputDefinition.RegisterOptions);
    formContext.setValue(inputName, value);
  }, [formContext, inputName, props.inputDefinition.RegisterOptions, value]);

  const onChangeCallback = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      formContext.setValue(inputName, newValue);
      props.onChange(event, newValue);
    },
    [props, inputName]
  );

  const onKeyUp = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      if (event.key === 'Enter' && props.onEnter) {
        event.preventDefault();
        event.stopPropagation();
        props.onEnter();
      }
    },
    [props.onEnter]
  );

  const errorMessage = formState.errors[inputName];
  return props.inputDefinition.Mask ? (
    <MaskedTextField
      label={props.inputDefinition.Label}
      placeholder={props.inputDefinition.Placeholder}
      id={props.id}
      onBlur={props.onBlur}
      disabled={props.disabled}
      mask={props.inputDefinition.Mask}
      errorMessage={errorMessage ? (errorMessage.message as unknown as string) : undefined}
      value={value}
      name={inputName}
      onChange={onChangeCallback}
      onKeyUp={props.onEnter !== undefined ? onKeyUp : undefined}
      styles={props.styles}
    />
  ) : (
    
      <TextField
        label={props.inputDefinition.Label}
        placeholder={props.inputDefinition.Placeholder}
        id={props.id}
        onBlur={props.onBlur}
        disabled={props.disabled}
        errorMessage={errorMessage ? (errorMessage.message as unknown as string) : undefined}
        value={value}
        name={inputName}
        onChange={onChangeCallback}
        multiline={props.multiline}
        autoAdjustHeight={props.autoAdjustHeight}
        borderless={props.borderless}
        prefix={props.prefix}
        type={props.type}
        onKeyUp={props.onEnter !== undefined ? onKeyUp : undefined}
        styles={props.styles}
      />
   
  );
};

export default observer(StandardTextBox);
