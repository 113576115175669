/**
 *
 * numberHelpers
 *
 */
export const formatCurrency = (
  amount: number | undefined | null,
  excludeCents: boolean = false,
  roundUp: boolean = false,
  currency: string = 'USD',
  locale: string = 'en-US'
): string => {
  if (amount === undefined || amount == null || isNaN(amount)) {
    amount = 0.00;
  }
  if (roundUp) {
    amount = Math.ceil(amount);
  }
  
  
  return amount.toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: excludeCents ? 0 : 2,
    maximumFractionDigits: excludeCents ? 0 : 2
  });
};

export const formatPercentage = (amount: number | undefined | null, locale: string = 'en-US'): string => {
  if (amount === undefined || amount == null || isNaN(amount)) {
    amount = 0;
  }
  return amount.toLocaleString(locale, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
};

// Expand below function for different locales
export const getCurrencySymbol = (): string => {
  return '$';
};

export const getNumericValueFromForm = (isNumericOrBoolean: boolean | undefined, value: number | string | null) => {
  if (value == null || value === undefined) {
    return null;
  }
  if (isNumericOrBoolean) {
    if (value === 'true') {
      return true;
    }
    if (value === 'false') {
      return false;
    }
    return Number(value);
  } else {
    return value;
  }
};

export const removeNonNumericalValues = (n?: string | null) => {
  return n ? n.replace(/\D/g, '') : '';
};
