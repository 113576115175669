import { Card } from 'src/components/base/container';
import { siteColors } from 'src/constants/themes';
import styled from 'styled-components';

export const ModalContainer = styled.div`
  justify-content: center;
  background-color: #ecf2fb;
  display: flex;
  height: 100%;
  min-height: 100%;
  max-width: 400px;
  flex-direction: column;
  margin: auto;
  padding: 20px;
  padding-bottom: 40px;
  padding-top: 40px;
`;

export const ModalCard = styled(Card)`
  overflow: visible;
  background-color: white;
  display: flex;
  position: relative;
  padding: 80px 16px 24px 16px;
  flex-direction: column;
`;

export const TitleBox = styled.div`
  margin-top: 24px;
`;

export const ModalTitle = styled.div`
  font-weight: 400;
  font-size: 1.875rem;
`;
export const ModalSubTitle = styled.div`
  color: #546e7a;
  font-size: 1rem;
`;

export const Lock = styled.div`
  background-color: ${siteColors.success};
  color: white;
  border-radius: 5px;
  padding: 8px;
  position: absolute;
  top: 10px;
  left: 16px;
  height: 48px;
  width: 48px;
`;
