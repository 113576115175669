import React from 'react';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { AdminBox, Button, Link, ListItem, Title } from './styledComponents';
interface props {
  item: any;
  handleNavbarClose: () => void;
}

export const AdminbarItem = ({ item, handleNavbarClose }: props) => {
  const { highlight, link, icon: Icon, title } = item;

  

  return (
    <ListItem>
      <Button onClick={() => handleNavbarClose()}>
        <Link exact activeStyle={{ color: '#5850ec', fontWeight: 500 }} to={link}>
          <AdminBox>{Icon && <FontAwesomeIcon size={'lg'} icon={Icon} />}</AdminBox>
          <Title>{title}</Title>
        </Link>
      </Button>
    </ListItem>
  );
};
