import { IObservableArray, isObservableArray } from 'mobx';
import FormModel from '../../models/forms/FormModel';

export const arraySetData = (formModelArray: Array<FormModel>, TCreator: new () => FormModel, data: Array<any>) => {
  const copy = isObservableArray(data) ? data.slice() : data;
  if (isObservableArray(formModelArray)) {
    const newArray = new Array();
    copyDataIntoArray(newArray, TCreator, copy);
    const observableArray = formModelArray as IObservableArray<any>;
    observableArray.replace(newArray);
  } else {
    formModelArray.length = 0;
    copyDataIntoArray(formModelArray, TCreator, copy);
  }
};

const copyDataIntoArray = (modelArray: Array<FormModel>, TCreator: new () => FormModel, dataToCopy: Array<any>) => {
  if (dataToCopy == null) {
    return new Array<FormModel>();
  }

  dataToCopy.map((x) => {
    const t = new TCreator();
    t.SetData(x);
    modelArray.push(t);
  });
};

export const getYesNoFromBoolean = (val: boolean) => {
  switch (val) {
    case true:
      return 'Yes';
    case false:
      return 'No';
  }
};
