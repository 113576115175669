import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import LoadingComponent from 'src/components/common/loaders/LoadingComponent';
import {
  AboutUsLoader,
  AddAuctionLotLoader,
  AddNewAuctionLoader,
  AdminMainLoader,
  ApprovedBuyersLoader,
  AttendeesReportLoader,
  AuctionChatLogLoader,
  AuctionEmailLoader,
  AuctionListLoader,
  AuctionListViewLotLoader,
  AuctionLotDetailLoader,
  AuctionLotLoader,
  AuctionReportLoader,
  AuctionTransactionLogLoader,
  BuyerApprovalLoader,
  ContactInfoLoader,
  ContactsLoader,
  CreateAccountLoader,
  EditAccountLoader,
  EditAuctionLoader,
  EditLotDetailLoader,
  EmailManagerLoader,
  ExtSaleListLoader,
  ForthcomingAuctionsLoader,
  ManageSaleTypeLoader,
  ManageUsersEditLoader,
  ManageUsersLoader,
  MarketDetailLoader,
  MarketReportLoader,
  PrivacyPolicyLoader,
  PublishLoader,
  PureBredBuyersLoader,
  RecoverAccountLoader,
  ResetPasswordLoader,
  SubscribeLoader,
  SupportLoader,
  TermsOfServiceLoader,
  UserLoginReportLoader,
  VerifyAccountLoader,
  VideoIntegrationLoader,
  VideoIntegrationNewLoader,
  ViewChatLogLoader
} from 'src/components/common/loaders/routeLoader';
import ActiveAuctions from 'src/containers/ActiveAuctions/ActiveAuctions';
import AdminLayout from 'src/containers/base/Layout/AdminLayout';
import ProfileLayout from 'src/containers/base/Layout/ProfileLayout';
import LegacySiteViewer from 'src/containers/legacyIntegration/LegacySiteViewer';
import OverlayLoadingSpinner from 'src/containers/loadingSpinner/OverlayLoadingSpinner';

import Layout from '../containers/base/Layout/layout';
const Modal = React.lazy(() => import('src/components/common/Modal/ModalContainer'));
const ModalDrawer = React.lazy(() => import('src/components/common/Modal/ModalDrawer'));
export const StreamingLegacy = ({ match }) => {
  return (
    <Switch>
      <Route exact path={'/integration'}>
        <LegacySiteViewer />
      </Route>
      <Route
        path={`${match.url}/Publish`}
        exact={true}
        component={LoadingComponent.GetLoadingComponent(PublishLoader)}
      />
      <Route
        path={`${match.url}/Subscribe`}
        exact={true}
        component={LoadingComponent.GetLoadingComponent(SubscribeLoader)}
      />
      <Route
        path={`${match.url}/VideoIntegration`}
        exact={true}
        component={LoadingComponent.GetLoadingComponent(VideoIntegrationNewLoader)}
      />
      <Route
        path={`${match.url}/integration`}
        exact={true}
        component={LoadingComponent.GetLoadingComponent(VideoIntegrationLoader)}
      />
    </Switch>
  );
};

export const MainSite = () => {
  return (
    <>
      <React.Suspense fallback={<div />}>
        <Modal />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <ModalDrawer />
      </React.Suspense>
      <ToastContainer />
      <OverlayLoadingSpinner />
      <Switch>
        <Route path={'/Report'} component={Report} />
        <Route path={'/Info'} component={Info} />
        <Route path={'/Account'} component={Account} />
        <Route path={'/Admin'} component={Admin} />
        <Layout>
          <Route exact path={'/integration'}>
            <LegacySiteViewer />
          </Route>
          <Route exact path={'/'}>
            <ActiveAuctions />
          </Route>
        </Layout>
      </Switch>
    </>
  );
};

export const Report = ({ match }) => {
  return (
    <Switch>
      <Layout>
        <Route
          path={`${match.url}/MarketReport`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(MarketReportLoader)}
        />
        <Route
          path={`${match.url}/MarketDetail`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(MarketDetailLoader)}
        />
        <Route
          path={`${match.url}/AuctionLot`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(AuctionLotLoader)}
        />
        <Route
          path={`${match.url}/Lot`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(AuctionLotDetailLoader)}
        />
        <Route
          path={`${match.url}/ExtSalesList`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(ExtSaleListLoader)}
        />
      </Layout>
    </Switch>
  );
};

export const Info = ({ match }) => {
  return (
    <Switch>
      <Layout>
        <Route
          path={`${match.url}/Markets`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(ContactsLoader)}
        />
        <Route
          path={`${match.url}/AgentReps`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(ContactsLoader)}
        />
        <Route
          path={`${match.url}/Support`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(SupportLoader)}
        />
        <Route
          path={`${match.url}/BuyerApproval`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(BuyerApprovalLoader)}
        />
        <Route
          path={`${match.url}/AboutUs`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(AboutUsLoader)}
        />
        <Route
          path={`${match.url}/PrivacyPolicy`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(PrivacyPolicyLoader)}
        />
        <Route
          path={`${match.url}/TermsOfService`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(TermsOfServiceLoader)}
        />
      </Layout>
    </Switch>
  );
};
export const Account = ({ match }) => {
  return (
    <Switch>
      <ProfileLayout>
        <Route
          path={`${match.url}/Register`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(CreateAccountLoader)}
        />
        <Route
          path={`${match.url}/EditProfile`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(EditAccountLoader)}
        />
        <Route
          path={`${match.url}/Verification`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(VerifyAccountLoader)}
        />
        <Route
          path={`${match.url}/LostPassword`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(RecoverAccountLoader)}
        />
        <Route
          path={`${match.url}/CompleteRecovery`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(ResetPasswordLoader)}
        />
      </ProfileLayout>
    </Switch>
  );
};

export const Streaming = ({ match }) => {
  return (
    <Switch>
      <Layout>
        <Route
          path={`${match.url}/Publish`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(PublishLoader)}
        />
        <Route
          path={`${match.url}/Subscribe`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(SubscribeLoader)}
        />
        <Route
          path={`${match.url}/VideoIntegration`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(VideoIntegrationNewLoader)}
        />
      <Route
        path={`${match.url}/AdImageSetup`}
        exact={true}
        component={LoadingComponent.GetLoadingComponent(AdminMainLoader)}
      />
      </Layout>
    </Switch>
  );
};

export const Admin = ({ match }) => {
  return (
    <Switch>
      <AdminLayout>
        {/* <Route
          path={`${match.url}/AdImageSetup`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(AdminMainLoader)}
        /> */}
        <Route
          path={`${match.url}/AddNewAuction`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(AddNewAuctionLoader)}
        />
        <Route
          path={`${match.url}/EditAuction`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(EditAuctionLoader)}
        />
        <Route
          path={`${match.url}/AddLot`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(AddAuctionLotLoader)}
        />
        <Route
          path={`${match.url}/AttendeesReport`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(AttendeesReportLoader)}
        />
        <Route
          path={`${match.url}/AuctionList`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(AuctionListLoader)}
        />
        <Route
          path={`${match.url}/AuctionEmail`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(AuctionEmailLoader)}
        />
        <Route
          path={`${match.url}/AuctionLots`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(AuctionListViewLotLoader)}
        />
        <Route
          path={`${match.url}/LotDetails`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(EditLotDetailLoader)}
        />
        <Route
          path={`${match.url}/ContactInfo`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(ContactInfoLoader)}
        />
        <Route
          path={`${match.url}/EmailManager`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(EmailManagerLoader)}
        />
        <Route
          path={`${match.url}/ManageSaleType`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(ManageSaleTypeLoader)}
        />
        <Route
          path={`${match.url}/ManageUsers`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(ManageUsersLoader)}
        />
        <Route
          path={`${match.url}/ManageUsers/EditUser`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(ManageUsersEditLoader)}
        />
        <Route
          path={`${match.url}/UserLoginReport`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(UserLoginReportLoader)}
        />
        <Route
          path={`${match.url}/ViewChatLog`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(ViewChatLogLoader)}
        />
        <Route
          path={`${match.url}/ChatLog`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(AuctionChatLogLoader)}
        />
        <Route
          path={`${match.url}/TransactionLog`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(AuctionTransactionLogLoader)}
        />
        <Route
          path={`${match.url}/ReportSale`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(AuctionReportLoader)}
        />
        <Route
          path={`${match.url}/BuyerApproval`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(PureBredBuyersLoader)}
        />
        <Route
          path={`${match.url}/ApprovedBuyer`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(ApprovedBuyersLoader)}
        />
        <Route
          path={`${match.url}/ForthcomingAuctions`}
          exact={true}
          component={LoadingComponent.GetLoadingComponent(ForthcomingAuctionsLoader)}
        />
      </AdminLayout>
    </Switch>
  );
};
