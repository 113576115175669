import { runInAction } from 'mobx';
import ResponseModel from 'src/models/account/ResponseModel';
import ExtSaleListingModel from 'src/models/SalesListings/ExtSaleListingModel';
import AdminStore from 'src/stores/AdminStore';
import ServiceBase from '../Common/ServiceBase';


class ExtendedSaleListService extends ServiceBase {
  public getExtSalesListing = async (store: AdminStore) => {
    store.loading = true;
    const response = await this.get<Array<ExtSaleListingModel>>(`/ExtendedSaleListing`);
    runInAction(() => {
      const list = new Array<ExtSaleListingModel>();
      response.data.map((value) => {
        const sales = new ExtSaleListingModel();
        sales.SetData(value);
        list.push(value);
      });
      store.extSaleList.clear();
      store.extSaleList.replace(list);
      store.loading = false;
    });
  };

  public updateExtSaleListing = async (model: ExtSaleListingModel) => {
    const data = new FormData();
    data.append('sale', JSON.stringify(model));
    // if (model.AttachedFile) {
    //   data.append('UploadFile', model.AttachedFile);
    // }
    return await this.put<ResponseModel>(`/ExtendedSaleListing`, data);
  };
  public createExtSaleListing = async (model: ExtSaleListingModel) => {
    const data = new FormData();
    data.append('sale', JSON.stringify(model));
    // if (model.AttachedFile) {
    //   data.append('UploadFile', model.AttachedFile);
    // }
    return await this.post<ResponseModel>(`/ExtendedSaleListing`, data);
  };
  public deleteExtSaleListing = async (id: number) => {
    return await this.delete(`/ExtendedSaleListing/${id}`);
  };
}

export default ExtendedSaleListService;
