import { makeObservable, observable } from 'mobx';
import FormModel from '../forms/FormModel';

export class CompanyContactModel extends FormModel {
  constructor(id?: number) {
    super();
    makeObservable(this);
    if (id) {
      this.companyId = id;
    }
    this.contactName = "";
    this.contactPosition = '';
    this.contactPhone = '';
    this.contactEmail = '';
  }
  @observable
  public contactName: string;
  @observable
  public contactPosition: string;
  @observable
  public contactPhone: string;
  @observable
  public contactEmail: string;
  @observable
  public contactId: number;
  @observable
  public sortOrder: number;
  @observable
  public companyId: number;
}
