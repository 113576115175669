import { device } from "src/constants/themes";
import styled from "src/utils/styled/styled";

export const Nav = styled.div`
    height: 100vh;
    width: 256px;
    @media ${device.desktop} {
      width: 0px;
    }
`;
export const AdminNav = styled(Nav)`
    height: 100vh;
    width: 200px;
    @media ${device.desktop} {
      width: 0px;
    }
`;

export const Root = styled.div`
display: flex;
height: 100vh;
`;
export const Content = styled.div`
    margin-top: 80px;
    overflow-y: scroll;
    display: flex;
    width: calc(100vw - 256px);
    height: calc(100vh - 80px);
    flex-direction: column;
    justify-content: space-between;
    @media ${device.desktop} {
      width: 100vw;
      overflow-x: hidden;
    }
    @media ${device.mobileM} {
      margin-bottom: 20px;
    }
`;

export const AdminContent = styled(Content)`
  width: calc(100vw - 200px);
`;