import styled from 'src/utils/styled/styled';
import { Card } from 'src/components/base/container';
export const ButtonLink = styled.a`
  color: white;
`;
export const ModalCard = styled(Card)`
  overflow: visible;
  background-color: white;
  display: flex;
  position: relative;
  padding: 16px;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  height: auto;
  max-width: 600px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 6px;
  border-radius: 4px 4px 0 0;
`;