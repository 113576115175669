import { observer } from 'mobx-react-lite';
import * as React from 'react';
import LoginModel from 'src/models/account/LoginModel';
import InputDefinitionBuilder from 'src/utils/inputDefinitions/InputDefinitionBuilder';
import { IInputFieldProps } from '../../common/IInputFieldProps';
import StandardTextBoxField from 'src/components/forms/textbox/StandardTextBoxField';

interface ILoginUsernameField extends IInputFieldProps {
  model: LoginModel;
}

const LoginUsernameField: React.FC<ILoginUsernameField> = (props: ILoginUsernameField) => {
  const [inputDefinition] = React.useState(
    new InputDefinitionBuilder('')
      .WithPlaceholder('Username')
      .Build()
  );

  return (
    <StandardTextBoxField
      name='username'
      value={props.model.username}
      onModelChange={props.model.OnModelChange}
      inputDefinition={inputDefinition}
      noStyle
    />
  );
};

export default observer(LoginUsernameField);
