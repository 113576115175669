import React, {
  createContext,
  useState,
  useEffect,
} from 'react';
import _ from 'lodash';
import { THEMES } from '@/constants/themes';
import { storeSettings } from '@/utils/settings/settings';

const defaultSettings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: THEMES.ONE_DARK,
};

const SettingsContext = createContext({
  settings: defaultSettings,
});

interface IProps {
  settings?: {
   direction: string;
    responsiveFontSizes: boolean;
    theme: string;
  },
  children: any,
}

const SettingsProvider = ({
  settings,
  children,
}: IProps) => {
  const [currentSettings, setCurrentSettings] = useState(settings || defaultSettings);

  const handleSaveSettings = (updatedSettings = {}) => {
    const mergedSettings = _.merge({}, currentSettings, updatedSettings);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  const value = {
    settings: currentSettings,
    saveSettings: handleSaveSettings,
  };

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
}

const SettingsConsumer = SettingsContext.Consumer;


export {
  SettingsProvider,
  SettingsConsumer,
}

export default SettingsContext;
