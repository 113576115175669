import {
 faUser, faListAlt, faAd, faFolderPlus, faAddressCard, faUserTag, faMoneyCheck, faChartLine, faEnvelopeOpenText, faChalkboard, faUserCog, faClipboardList} from '@fortawesome/free-solid-svg-icons'
import { siteUrls } from '../siteUrls';

const ADMIN_NAV_ITEMS = [
  {
    title: 'CONTACT INFO',
    icon: faUser,
    link: siteUrls.ContactInfoUrl,
    requireLogin: true
  },
  {
    title: 'AUCTION LIST',
    icon: faListAlt,
    link: siteUrls.AuctionListUrl,
    requireLogin: true
  },
  {
    title: 'ADD NEW AUCTION',
    icon: faFolderPlus,
    link: siteUrls.AddNewAuctionUrl,
    requireLogin: true
  },
  {
    title: 'EXT SALE LISTING',
    icon: faClipboardList,
    link: siteUrls.ExtSaleListingUrl,
    requireLogin: true
  },
  {
    title: 'ATTENDEES REPORT',
    icon: faAddressCard,
    link: siteUrls.AttendeesReportUrl,
    requireLogin: true
  },
  {
    title: 'MANAGE USERS',
    icon: faUserTag,
    link: siteUrls.ManageUsersUrl,
    requireLogin: true
  },
  {
    title: 'PUREBRED BUYERS',
    icon: faMoneyCheck,
    link: siteUrls.PureBredBuyersUrl,
    requireLogin: true
  },
  {
    title: 'MANAGE SALE TYPE',
    icon: faChartLine,
    link: siteUrls.ManageSaleTypeUrl,
    requireLogin: true
  },
  {
    title: 'EMAIL MANAGER',
    icon: faEnvelopeOpenText,
    link: siteUrls.EmailManagerUrl,
    requireLogin: true
  },
  {
    title: 'USER LOGIN REPORT',
    icon: faUserCog,
    link: siteUrls.UserLoginReportUrl,
    requireLogin: true
  },
  {
    title: 'AD IMAGE SETUP',
    icon: faAd,
    link: siteUrls.AdImageSetupUrl,
    requireLogin: true
  },
  {
    title: 'VIEW CHAT LOG',
    icon: faChalkboard,
    link: siteUrls.ViewChatLogUrl,
    requireLogin: true
  }
];

export { ADMIN_NAV_ITEMS };
