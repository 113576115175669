
import React from 'react';
import { TimesNewPageTitle } from 'src/components/base/styledComponents';
import SaleListingModel from 'src/models/SalesListings/saleListingModel';
import UpcomingAuctionDisplay from './UpcomingAuctionDisplay';

interface IProps {
  rows: Array<SaleListingModel>;
  title: string;
}

function UpcomingAuctionsList({ rows, title }: IProps) {
  
  return (
    <div>
      <TimesNewPageTitle isBlack>{title}</TimesNewPageTitle>
      {rows &&
        rows.map((sale, index) => {
          return <UpcomingAuctionDisplay key={index} sale={sale} />;
        })}
    </div>
  );
}

export default UpcomingAuctionsList;
