import { runInAction } from 'mobx';
import ResponseModel from 'src/models/account/ResponseModel';
import { CompanyInfoModel } from 'src/models/admin/CompanyInfoModel';
import { CompanyMasterModel } from 'src/models/admin/CompanyMasterModel';
import AdminStore from 'src/stores/AdminStore';

import ServiceBase from '../Common/ServiceBase';

class CompanyInfoService extends ServiceBase {
  public getCompanysInfo = async (store: AdminStore) => {
    const response = await this.get<Array<CompanyInfoModel>>(`/companyinfo`);
    runInAction(() => {
      const list = new Array<CompanyInfoModel>();
      response.data.map((value) => {
        const company = new CompanyInfoModel();
        company.SetData(value);
        list.push(value);
      });
      store.companyInfo.replace(list);
    });
  };
  public getCompanyMasterInfo = async () => {
    const response = await this.get<Array<CompanyMasterModel>>(`/companyinfo/master`);
    const list = new Array<CompanyMasterModel>();
    runInAction(() => {
      response.data.map((value) => {
        const company = new CompanyMasterModel();
        company.SetData(value);
        list.push(value);
      });
    });
    return list
  };

  public updateCompanyInfo = async (company: CompanyInfoModel) => {
    return await this.put<ResponseModel>(`/companyinfo`, company);
  };
  public createCompanyInfo = async (company: CompanyInfoModel) => {
    return await this.post<ResponseModel>(`/companyinfo`, company);
  };
}

export default CompanyInfoService;
