import { observer } from 'mobx-react-lite';
import React from 'react';
import { Container, FlexContainer } from 'src/components/base/container';
import SaleListingModel from 'src/models/SalesListings/saleListingModel';
import { DateTypeDisplay, CustomerText, BidderRegistrationNoImage, ImageDisplay } from './UpcomingAuctionComponents';
import { AuctionColorPicker } from 'src/common/AuctionColorPicker';
import { AuctionDescription, BorderBox, LinkBox } from '../styledComponents';
import { EmphasizedTextDisplay } from 'src/components/base/styledComponents';

interface IUpcomingAuctionDesktop {
  sale: SaleListingModel;
  link: string;
  setIsImage: (value: boolean) => void;
  isImage: boolean;
}

function UpcomingAuctionDesktop({ sale, link, setIsImage, isImage }: IUpcomingAuctionDesktop) {
  return (
    <BorderBox color={AuctionColorPicker(sale.auctionType)}>
      <FlexContainer columnGap={'10px'}>
        <FlexContainer column justifyContent={'flex-start'} >
          <LinkBox href={link}>
            <Container>
              <EmphasizedTextDisplay>{sale.auctionName}</EmphasizedTextDisplay>
            </Container>
            {isImage && <ImageDisplay sale={sale} setIsImage={setIsImage} />}
            <Container>
              <AuctionDescription>{sale.auctionDescription}</AuctionDescription>
            </Container>
          </LinkBox>
          <BidderRegistrationNoImage sale={sale} isImage={isImage} />
        </FlexContainer>
        <FlexContainer column style={{ width: 'max-content' }}>
          <DateTypeDisplay link={link} sale={sale} />
          <div style={{ marginLeft: '20px' }}>{isImage && <CustomerText sale={sale} justify direction />}</div>
        </FlexContainer>
      </FlexContainer>
    </BorderBox>
  );
}

export default observer(UpcomingAuctionDesktop);
