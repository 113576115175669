import { device, siteColors } from 'src/constants/themes';
import styled from '../../utils/styled/styled';
import { IButtonProps } from "./button";

interface ITitleProps {
  /** true for black, nothing for primary blue */
  isBlack?: boolean;
  center?: boolean;
  noMargin?: boolean;
  subHeader?: boolean;
}

export const PageTitle = styled.h3<ITitleProps>`
  font-size: ${(props) => (props.subHeader ? '1.29rem' : '1.496rem')};
  font-weight: ${(props) => (props.subHeader ? 450 : 500)};
  color: ${(props) => (props.isBlack ? 'black' : siteColors.primary)};
  text-align: ${(props) => (props.center ? 'center' : '')};
  margin: ${(props) => (props.noMargin ? 0 : '23.9px')};
  @media ${device.mobileM} {
    font-size: ${(props) => (props.subHeader ? '.9rem' : '1.1rem')};
  }
`;

export const TimesNewPageTitle = styled(PageTitle)`
  font-family: 'times new roman';
  font-weight: 600;
  font-size: '1.6rem';
  margin: 15px;
`;

export const EmphasizedTextDisplay = styled.div`
  font-size: 1rem;
  font-weight: 500;
  color: black;
  margin-bottom: 10px;
  `

export const StyledButton = styled.button<IButtonProps>`
  width: 93px;
  height: 40px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  line-height: 35px;
  background-color: ${props => props.theme.PrimaryColor};
  border-style: none;
  cursor: pointer;
  margin-right:5px;
  margin-top:5px;
  margin-bottom:5px;
  :hover {
    background-color: #3391b5;
  }
  
  :disabled{
    background-color: lightgray;
    cursor: not-allowed;
  }
`;

export const StyledCancelButton = styled(StyledButton)`
  background-color: ${props => props.theme.Red};
  color:white;
`

export const StyledVideoControlButton = styled(StyledButton)`
  background-color: green;
  border-radius:5px;
  font-size:.8em;
  margin:2px;
  width:80px;
  height:30px;
  font-weight:unset;
  line-height:unset;
`;

export const StyledLink = styled.a`
  color: ${siteColors.link};
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;

export const LargeLink = styled(StyledLink)`
  font-weight: bold;
  font-size: 1rem;
`;

export const SpanLinkColored = styled.span`
  color: ${siteColors.link};
  text-decoration: underline;
  text-transform: uppercase;
  padding: 5px;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`;


export const Divider = styled.hr`
  border-top: 1px solid #bbb;
`;