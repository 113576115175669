import React from 'react'
import {Link} from 'react-router-dom'
import { siteColors } from 'src/constants/themes';
import styled from 'src/utils/styled/styled';

const StyledLink = styled.a`
  color: ${siteColors.link};
  font-size: 1rem;
  text-decoration: underline;
  width: max-content;
  &:hover {
    text-decoration: none;
  }
`;

interface IRouteLinkProps{
    to: string;
    onClick?: () => void;
}

export const RouteLink: React.FC<IRouteLinkProps> = (props) => {
    return <Link component={StyledLink} onClick={props.onClick} to={props.to}>{props.children}</Link>;
};