import { makeObservable, observable } from 'mobx';
import FormModel from 'src/models/forms/FormModel';



export class AuctionListDisplayModel extends FormModel {
  constructor() {
    super();
    makeObservable(this);
    
  }
  @observable
  public auctionId: number;
  @observable
  public auctionName: string;
  @observable
  public auctionDate: string;
  @observable
  public auctionTime: string;
  @observable
  public auctionType: number;
  @observable
  public description: number;
  @observable
  public auctionStatus: string;
  @observable
  public headCount: number;
  @observable
  public lotCount: number;


}
