import axios, { AxiosError, AxiosResponse } from 'axios';
import { ApiUrls } from 'src/constants/apiUrls';

const responseBody = <T>(response: AxiosResponse<T>) => response;

abstract class ServiceBase {
  constructor() {
    axios.defaults.baseURL = ApiUrls.GetBaseUrl();
    axios.defaults.withCredentials = true;
  }

  protected post = async <T>(url?: any, params?: any) => {
    const request = axios.post<T>(url, params).then(responseBody);

    return await request;
  };

  protected get = async <T>(url) => {
    const request = axios.get<T>(url).then(responseBody);

    return await request;
  };

  protected put = async <T>(url?: any, params?: any) => {
    const request = axios.put<T>(url, params).then(responseBody);

    return await request;
  };
  protected delete = async <T>(url?: any) => {
    const request = axios.delete<T>(url).then(responseBody);

    return await request;
  };
}

export default ServiceBase;
