import { makeObservable, observable } from 'mobx';
import { ICompanyName } from 'src/interfaces/Admin/ICompanyName';
import { ICountry, IProvince } from 'src/interfaces/Users/RegisterInterfaces';
import FormModel from '../forms/FormModel';


export class CompanyInfoModel extends FormModel implements ICountry, IProvince, ICompanyName{
  constructor() {
    super();
    makeObservable(this);
  }

  @observable
  public companyName: string;
  @observable
  public companyMasterId: number;
  @observable
  public address: string;
  @observable
  public city: string;
  @observable
  public country: string;
  @observable
  public province: string;
  @observable
  public postalZipCode: string;
  @observable
  public phoneNumber: string;
  @observable
  public faxNumber: string;
  @observable
  public tollFree: string;
  @observable
  public companyId: number;
  @observable
  public email: string;
  @observable
  public buyerEmailFooter: string;

}
