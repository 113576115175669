import { RouterStore } from 'mobx-react-router';
import React from 'react';
import AdminStore from './AdminStore';
import LiveStreamStore from './liveStreamStore';
import lotListStore from './lotListStore';
import SaleListStore from './saleListStore';
import userStore from './userStore';
import UtilityStore from './utilityStore';



const createStores = () => {
  var utilStore = new UtilityStore();
  return {
    userStore: new userStore(),
    saleListStore: new SaleListStore(),
    liveStreamStore: new LiveStreamStore(),
    routerStore: new RouterStore(),
    utilityStore: utilStore,
    adminStore: new AdminStore(),
    lotListStore: new lotListStore(utilStore)
  };
};



export const StoresContext = React.createContext(createStores());

export const useStore = () => React.useContext(StoresContext);

export const useUserStore = () => {
  return useStore().userStore;
}
export const useAdminStore = () => {
  return useStore().adminStore;
}
export const useRouterStore = () => {
  return useStore().routerStore;
}
export const useSaleListStore = () => {
  return useStore().saleListStore;
}
export const useLiveStreamStore = () => {
  return useStore().liveStreamStore;
}
export const useUtilityStore = () => {
  return useStore().utilityStore;
}
export const useLotListStore = () => {
  return useStore().lotListStore;
}
