import React from 'react';
import { LegacyApiUrls } from 'src/constants/legacyApiUrls';

interface ILegacySiteLink {
  oldUrl: string;
  children?: any;
  target?: string;
}
const LegacySiteLink: React.FC<ILegacySiteLink> = (props: ILegacySiteLink) => {
  let target = props.target;
  if (props.target == null) {
    target = '_blank';
  }
  return (
    <a target={target} href={`${LegacyApiUrls.GetBaseUrl()}${props.oldUrl}`}>
      {props.children}
    </a>
  );
};

export default LegacySiteLink;
