import { observable } from "mobx";
import StreamType from "../../enums/streamType";

export class LiveStreamModel {

    constructor(displayName: string, streamName: string, type: StreamType) {
        this.displayName = displayName;
        this.streamName = streamName;
        this.type = type;
    }

    @observable
    public streamName: string;

    @observable
    public type: StreamType;

    public displayName: string;
}