import { siteUrls } from "../siteUrls";

const FOOTER_ITEMS = [
  {
    title: 'Home',
    link: siteUrls.HomeUrl
  },
  {
    title: 'About Us',
    link: siteUrls.AboutUsUrl
  },
  {
    title: 'Contact Us',
    link: siteUrls.AgentRepsUrl
  },
  {
    title: 'Market Report',
    link: siteUrls.MarketReportUrl
  },
  {
    title: 'All Sale Listing',
    link: siteUrls.ExtSalesListUrl
  },
  {
    title: 'Support / Demo',
    link: siteUrls.SupportUrl
  },
  {
    title: 'Privacy Policy',
    link: siteUrls.PrivacyPolicyUrl
  },
  {
    title: 'Terms of Service',
    link: siteUrls.TermsOfServiceUrl
  }
];

export { FOOTER_ITEMS };
