import axios from 'axios';
import { ApiUrls } from "../../constants/apiUrls";
import { LiveStreamModel } from "./liveStreamModel";

export class LiveStreamService {
  public Get(destination: Array<LiveStreamModel>) {
    axios.get(ApiUrls.LiveStreams).then((data) => {
      for (const i in data.data) {
        const liveStream = data.data[i];
        destination.push(new LiveStreamModel(liveStream.displayName, liveStream.streamName, liveStream.type));
      }
    });

    return destination;
  }

  public GetToken(channelName: string, onSuccess: (token: string) => void) {
    axios.get(ApiUrls.StreamAdministration + `/streaming/?channelName=${channelName}`).then(data => {
      onSuccess(data.data);
    });
  }

  public GetViewerToken(channelName: string, onSuccess: (token: string) => void) {
    axios.get(ApiUrls.StreamAdministration + `/viewing/?channelName=${channelName}`).then(data => {
      onSuccess(data.data);
    });
  }
}