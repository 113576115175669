import React from 'react';
import { getQueryParam } from 'src/common/queryStrings';
import { LegacyApiUrls } from 'src/constants/legacyApiUrls';

const LegacySiteViewer = () => {
  let urlFromQuery = getQueryParam('url').toString();
  debugger;
  let queryString = '&';
  if (urlFromQuery.indexOf('?') < 0) {
    queryString = '?';
  }
  if (urlFromQuery.indexOf('/') === 0) {
    urlFromQuery = urlFromQuery.substring(1);
  }
  const fullUrl = `${LegacyApiUrls.GetBaseUrl()}/${decodeURIComponent(
    urlFromQuery
  )}${queryString}reactIntegration=true`;

  const styles = {
    paddingTop: '15px'
  };

  return <iframe style={styles} width='100%' height='100%' title='integration' src={fullUrl} />;
}

export default LegacySiteViewer;
