const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};


export {
  THEMES,
};



const size = {
  xs: 0,
  sm: '600px',
  md: '960px',
  lg: '1280px',
  xl: '1920px',
  smUp: '601px',
  mdUp: '961px',
  lgUp: '1281px',
  xlUp: '1921px'
};

export const siteColors = {
  primary: '#203260',
  primaryHover: '#101930',
  link: '#990000',
  linkHover: '#4c0000',
  header: '#daa520',
  hover: '#fff0c9',
  tableCell: '#ebf0ff',
  badge: '#f44336',
  success: '#28a745',
  successHover: '#218838',
  disabled: '#6c757d'
};

export const auctionColors = {
  purebred: '#139d45',
  purebredDark: '#0e7533',
  purebredLight: '#a8f7c5',
  presort: '#ef3f0e',
  presortDark: '#b32f0a',
  feeders: '#203260',
  feedersDark: '#1c2b52',
  event: '#3391b5',
  eventDark: '#266c87',
  timed: '#800080',
  timedDark: '#600060',
  other: '#c25b0c',
  otherDark: '#914409',
  upCommingTimed: '#3ab2d1',
  upCommingTimedDark: '#1c90ad',
  upCommingSale: '#5850EC',
  upCommingSaleDark: '#150f8e'
};

export const auctionGradients = {
  purebred: '#139d45, #15a649, #18ae4e, #1ab752, #1cc057',
  presort: '#ef3f0e, #f0491b, #f25226, #f35b30, #f4633a',
  feeders: '#203260, #25386a, #293e74, #2e457f, #334b89',
  event: '#3391b5, #4197b9, #4d9dbc, #58a3c0, #63a9c4',
  timed: '#800080, #821b82, #842a84, #853685, #874187',
  other: '#c25b0c, #c7641b, #cc6d27, #d17632, #d67f3d'
};


export const device = {
  mobileS: `(max-width: ${size.xs})`,
  mobileM: `(max-width: ${size.sm})`,
  tablet: `(max-width: ${size.md})`,
  desktop: `(max-width: ${size.lg})`,
  desktopL: `(max-width: ${size.xl})`
};

export const deviceUp = {
  mobileS: `(min-width: ${size.xs})`,
  mobileM: `(min-width: ${size.smUp})`,
  tablet: `(min-width: ${size.mdUp})`,
  desktop: `(min-width: ${size.lgUp})`,
  desktopL: `(min-width: ${size.xlUp})`
};


