import { makeObservable, observable } from 'mobx';
import FormModel from '../forms/FormModel';


export class CompanyMasterModel extends FormModel {
  constructor() {
    super();
    makeObservable(this);
  }

  @observable
  public companyMasterName: string;
  @observable
  public companyMasterId: number;
}
