import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import banner1 from '../../../../assets/images/banner.jpg';
import { Carousel } from 'react-responsive-carousel';
import styled from 'src/utils/styled/styled';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import BannerService from 'src/services/Admin/BannerService';


const CarouselImage = styled.div`
  height: 230px;
  width: 100%;
  background-size: cover;
  background-position: center;
`;

const AuctionsCarousel: React.FC = () => {
  const [loadedBanners, setLoadedBanners] = React.useState<Array<string>>([`url(${banner1})`]);
  const [loading, setLoading] = React.useState<boolean>(true)
  React.useEffect(() => {
    const GetBanners = async () => {
      const bannerService = new BannerService();
      const response = await bannerService.getBanners(true)
      let bannerArray = new Array<string>();
      response.data.map((item) => bannerArray.push(`url(data:image/jpeg;base64,${item.image}`));
      setLoadedBanners(bannerArray);
      setLoading(false)
    };
    GetBanners()
  }, []);
  
  
  if (loading) return <CarouselImage style={{ backgroundImage: `url(${banner1})` }}></CarouselImage>;
  return (
    <Carousel
      autoPlay={true}
      infiniteLoop={true}
      interval={10000}
      showIndicators={false}
      showArrows={false}
      autoFocus={true}
      showStatus={false}
      showThumbs={false}>
      {loadedBanners.map((item, i) => (
        <CarouselImage key={i} style={{ backgroundImage: item }}></CarouselImage>
      ))}
    </Carousel>
  );
}


export default AuctionsCarousel;