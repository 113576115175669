import dayjs, { Dayjs } from 'dayjs';
import { stringIsNullOrEmpty } from 'src/utils/helpers/stringHelpers';
import formatters from './formatters';



export const SetDatePropertyName = (dbData: any, model: any, propertyName: string) => {
  if (!stringIsNullOrEmpty(dbData[propertyName])) {
    let dbStartDate = dbData[propertyName] as string;
    if (dbStartDate.endsWith('Z')) {
      //remove timezone information from start/end dates as they do not have times at all. only dates
      dbStartDate = dbStartDate.substring(0, dbStartDate.length - 1);
    }
    model[propertyName] = dayjs(dbStartDate, formatters.momentDate).format(formatters.momentDate);
  }
};
