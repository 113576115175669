import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FlexContainer } from 'src/components/base/container';
import { HiddenMobileDown } from 'src/components/base/hidden';
import LegacySiteIntegrationLink from 'src/components/common/base/LegacySiteIntegrationLink';
import LoginModel from 'src/models/account/LoginModel';
import { useUserStore } from 'src/stores/useMobxStores';
import { toast } from 'react-toastify';
import {
  LoginControl,
  CreateLink,
  LoginButton,
  LoginLinks,
  ForgotLink
} from './styledComponents';
import LoginUsernameField from 'src/components/forms/inputfields/account/login/LoginUsernameField';
import FormWrapper from 'src/app/form/FormWrapper';
import LoginPasswordField from 'src/components/forms/inputfields/account/login/LoginPasswordField';
interface IProps {}

function Login(props: IProps) {
  const userStore = useUserStore();
  const location = useLocation();
  const history = useHistory();
  const [user] = useState(new LoginModel());


  const handleLoginClick = async () => {
    try {
      const response = await userStore.Login(user);
      if (response) {
        toast.success('Login successful, Welcome Back', { position: 'top-center' });
         if (location.pathname === '/Account/Register') history.replace('/');
         if (history.location.search === '?url=%2FAccount%2FLostPassword') history.replace('/');
      } else {
        toast.error(
          "Your account has been created but requires email verification before you may log in. Please check your email for a verification link. Don't see the email? Please check your spam folder or contact us directly for assistance!"
        );
      }

    } catch (error) {
      toast.error('Username or Password is incorrect, please try again', { position: 'top-center' });
      user.password = '';
    }
  };

  const loginControl = () => {
    return (
      <HiddenMobileDown>
        <FormWrapper>
          <LoginControl>
              <FlexContainer justifyContent={'center'} alignItems={'center'} gap={'3px'}>
                <LoginUsernameField model={user} />
                <LoginPasswordField model={user} />
                <LoginButton onClick={handleLoginClick}>LOGIN</LoginButton>
              </FlexContainer>
            <LoginLinks>
              <ForgotLink>
                <Link to={'/Account/LostPassword'}>Forgot Password</Link>
              </ForgotLink>
              <CreateLink>
                <span>|</span>
                <Link to='/Account/Register'>Create Account</Link>
              </CreateLink>
            </LoginLinks>
          </LoginControl>
        </FormWrapper>
      </HiddenMobileDown>
    );
  };

  return loginControl();
}

export default observer(Login);
