import { observer } from 'mobx-react-lite';
import React from 'react';
import { FlexItem } from 'src/components/base/container';
import LoadingSpinner from 'src/components/common/base/LoadingSpinner';
import { useUtilityStore } from '../../stores/useMobxStores';
import styled from '../../utils/styled/styled';

export const VisibleOverlay = styled.div`
  z-index: 100000;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: #000000;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StyledH5 = styled.h5`
  color: white;
`;

interface IOverlayLoadingSpinner {}

const OverlayLoadingSpinner: React.FC<IOverlayLoadingSpinner> = (props) => {
  const store = useUtilityStore();

  if (store.spinnerState.IsOpen) {
    return (
      <VisibleOverlay>
        <FlexItem>
          <LoadingSpinner />
        </FlexItem>
        <FlexItem>
          <StyledH5>{store.spinnerState.Message}</StyledH5>
        </FlexItem>
      </VisibleOverlay>
    );
  }
  return null;
};

export default observer(OverlayLoadingSpinner);
