import { observer } from 'mobx-react-lite';
import React from 'react';
import { AuctionGradientPicker } from 'src/common/AuctionColorPicker';
import { formatDate, formatTime } from 'src/common/formatters';
import { FlexContainer } from 'src/components/base/container';
import SaleListingModel from 'src/models/SalesListings/saleListingModel';
import { ActiveAuctionButton, ActiveAuctionDescription } from './ActiveAuctionComponents';
import { DateContainer, ActiveDisplay, TypeContainer } from './styledcomponents';

interface IActiveAuctionMobile {
  sale: SaleListingModel;
}

const ActiveAuctionMobile: React.FC<IActiveAuctionMobile> = ({ sale }: IActiveAuctionMobile) => {
  if (!sale) return <></>;
  return (
    <FlexContainer gap={'10px'} column>
      <FlexContainer justifyContent={'flex-end'}>
        <ActiveAuctionButton sale={sale} />

        <TypeContainer color={AuctionGradientPicker(sale.auctionType)}>{sale.auctionTypeDisplay}</TypeContainer>
      </FlexContainer>
      <ActiveDisplay>
        <ActiveAuctionDescription sale={sale} />
      </ActiveDisplay>
    </FlexContainer>
  );
}

export default observer(ActiveAuctionMobile);
