import { observer } from 'mobx-react-lite';
import React from 'react';
import { Card, Container, FlexContainer } from 'src/components/base/container';
import SaleListingModel from 'src/models/SalesListings/saleListingModel';
import { BidderRegistrationNoImage, ImageDisplay } from './UpcomingAuctionComponents';
import { AuctionColorPicker, AuctionGradientPicker } from 'src/common/AuctionColorPicker';
import { AuctionDescription, BorderBox, DateContainer, DateType, LinkBox, TabletLinkBox, TypeContainer } from '../styledComponents';
import { formatDate, formatTime } from 'src/common/formatters';
import LinkDisplayIcons from 'src/components/common/LinkDisplayIcons/LinkDisplayIcons';
import { EmphasizedTextDisplay } from 'src/components/base/styledComponents';


interface IUpcomingAuctionTablet {
  sale: SaleListingModel;
  isImage: boolean;
  link: string;
  setIsImage: (value: boolean) => void;
}

function UpcomingAuctionTablet({ sale, isImage, link, setIsImage }: IUpcomingAuctionTablet) {
  return (
    <Card>
      <BorderBox color={AuctionColorPicker(sale.auctionType)}>
        <FlexContainer columnGap={'10px'}>
          <FlexContainer column justifyContent={'flex-start'}>
            <LinkBox href={link}>
              <Container>
                <EmphasizedTextDisplay>{sale.auctionName}</EmphasizedTextDisplay>
              </Container>
              {isImage && (
               <ImageDisplay sale={sale} setIsImage={setIsImage} />
              )}
            </LinkBox>
            <BidderRegistrationNoImage sale={sale} isImage={true} />
          </FlexContainer>
          <FlexContainer column rowGap={'10px'} style={{ width: 'max-content' }}>
            <FlexContainer>
              <DateType>
                <DateContainer>
                  <TabletLinkBox href={link}>
                    {formatDate(sale.auctionDate)} {formatTime(sale.auctionDate)} {sale.timeZone.replace('S', '')}
                  </TabletLinkBox>
                </DateContainer>
                <LinkBox href={link}>
                  <TypeContainer color={AuctionGradientPicker(sale.auctionType)}>
                    {sale.auctionTypeDisplay}
                  </TypeContainer>
                </LinkBox>
              </DateType>
            </FlexContainer>
            <LinkDisplayIcons sale={sale} />
          </FlexContainer>
        </FlexContainer>
        <Container>
          <LinkBox href={link}>
            <AuctionDescription>{sale.auctionDescription}</AuctionDescription>
          </LinkBox>
        </Container>
      </BorderBox>
    </Card>
  );
}

export default observer(UpcomingAuctionTablet);
