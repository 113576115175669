import { observer } from 'mobx-react-lite';
import React from 'react';
import Header from 'src/components/common/Header/Header';
import Navbar from 'src/components/common/Navbar/Navbar';
import ErrorBoundary from 'src/components/errorBoundary/ErrorBoundary';
import Footer from '../../../components/common/Footer';
import { Content, Nav, Root } from './styledComponents';

const Layout: React.FC = (props) => {
  return (
    <Root>
      <Nav>
        <Navbar />
      </Nav>
      <Header />

      <Content>
        <ErrorBoundary>
          {props.children}
        </ErrorBoundary>
        <Footer />
      </Content>
    </Root>
  );
};

export default observer(Layout);
