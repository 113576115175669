import { observer } from 'mobx-react-lite';
import * as React from 'react';
import LoginModel from 'src/models/account/LoginModel';
import InputDefinitionBuilder from '../../../../../utils/inputDefinitions/InputDefinitionBuilder';
import StandardTextBoxField from '../../../textbox/StandardTextBoxField';
import { IInputFieldProps } from '../../common/IInputFieldProps';

interface ILoginPasswordField extends IInputFieldProps {
  model: LoginModel;
}

const LoginPasswordField: React.FC<ILoginPasswordField> = (props: ILoginPasswordField) => {
  const [inputDefinition] = React.useState(() => {
    return new InputDefinitionBuilder('')
      .WithPlaceholder('Password')
      .Build();
  });
  return (
    <StandardTextBoxField
      name='password'
      value={props.model.password}
      onModelChange={props.model.OnModelChange}
      inputDefinition={inputDefinition}
      type='password'
      noStyle
    />
  );
};

export default observer(LoginPasswordField);
