import { makeObservable, observable, runInAction } from 'mobx';
import  LotDetailModel  from 'src/models/common/AuctionLot/AuctionLotDetailModel';
import AuctionLotListModel from 'src/models/common/AuctionLot/AuctionLotListModel';
import AuctionService from 'src/services/Auction/AuctionService';
import UtilityStore from './utilityStore';


class lotListStore {
  constructor(utilStore: UtilityStore) {
    makeObservable(this);
    this.utilStore = utilStore;
    this.auctionLot = new LotDetailModel();
    this.auctionList = new AuctionLotListModel();
    this.isLoading = false;
    this.isLoadingLot = false;
  }
  @observable
  isLoading: boolean;
  @observable
  isLoadingLot: boolean;
  @observable
  auctionList: AuctionLotListModel;
  @observable
  auctionLot: LotDetailModel;
  @observable
  auctionLotId: number;
  private utilStore: UtilityStore;
  loadLotList = async (auctionId: number) => {
    this.isLoading = true;
    const auctionService = new AuctionService();
    const response = await auctionService.getAuctionLotListing(auctionId);
    runInAction(() => {
      this.auctionList = new AuctionLotListModel();
      this.auctionList.SetData(response.data);
      this.isLoading = false;
    });
  };

  loadLot = async (lotId: number) => {
    this.isLoadingLot = true;
    const auctionService = new AuctionService();
    const response = await auctionService.getAuctionLot(lotId);
    runInAction(() => {
      this.auctionLot = new LotDetailModel();
      this.auctionLot.SetData(response.data);
      this.auctionLot.consignorListingMarket = this.auctionLot.consignorName + ' / ' + this.auctionLot.listingMarket;
      this.auctionLotId = this.auctionLot.lotId;
      this.isLoadingLot = false;
    });
  };
}

export default lotListStore;
