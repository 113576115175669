import { makeAutoObservable } from 'mobx';
import SpinnerOverlayState from '../models/spinner/SpinnerOverlayState';

interface Modal {
  open: boolean;
  body: JSX.Element | null;
}

export type Color = 'success' | 'info' | 'warning' | 'error';
class UtilityStore {
  constructor() {
    makeAutoObservable(this);
    this.spinnerState = new SpinnerOverlayState();
  }
  spinnerState: SpinnerOverlayState;
  displayMenu: boolean = !this.isMobile();
  validate = { username: false, email: false };
  drawer: boolean = false;
  
  modal: Modal = {
    open: false,
    body: null
  };
  public isMobile(): Boolean {
    return window.innerWidth < 1000;
  }
  openDrawer = () => {
    this.drawer = true;
  };
  closeDrawer = () => {
    this.drawer = false;
  };
  openModal = (content: JSX.Element) => {
    this.modal.open = true;
    this.modal.body = content;
  };
  closeModal = () => {
    this.modal.open = false;
    this.modal.body = null;
  };
}
export default UtilityStore;
