import { makeObservable, observable } from 'mobx';
import { LiveStreamModel } from '../services/streaming/liveStreamModel';
import { LiveStreamService } from '../services/streaming/liveStreamService';

class LiveStreamStore {
  private hasInitialized: boolean;
  private liveStreamService: LiveStreamService;
  constructor() {
    this.liveStreamList = new Array<LiveStreamModel>();
    this.liveStreamService = new LiveStreamService();
    this.hasInitialized = false;
    makeObservable(this);
  }
  public get LiveStreamList(): Array<LiveStreamModel> {
    if (!this.hasInitialized) {
      this.liveStreamService.Get(this.liveStreamList);
      this.hasInitialized = true;
    }
    return this.liveStreamList;
  }
  @observable
  private readonly liveStreamList: Array<LiveStreamModel>;
}

export default LiveStreamStore;
