import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FlexContainer } from 'src/components/base/container';
import { useUserStore, useUtilityStore } from 'src/stores/useMobxStores';
import logo from '../../../assets/images/DLMS-Logo-Vector-2021-250x80.png';
import NavAds from '../Ads/NavAds';
import { NAV_ITEMS } from '../../../constants/NavConstants/NavBarConstants';
import { AuthBarItem } from './NavItems/AuthBarItem';
import { NavbarItem } from './NavItems/NavbarItem';
import { SignInItem } from './NavItems/SignInItem';
import { DrawerContainer, NavBox, Divider, ButtonContainer, CloseButton, Drawer, Logo } from './styledComponents';
import { List } from 'src/components/base/list';
import { HiddenDesktopDown, HiddenDesktopUp, HiddenTabletUp } from 'src/components/base/hidden';


function Navbar() {
  const userStore = useUserStore();
  const utilityStore = useUtilityStore();


  const content = (
    <DrawerContainer>
      <FlexContainer justifyContent='center'>
        <RouterLink to='/'>
          <Logo onClick={utilityStore.closeDrawer} src={logo} alt='logo' />
        </RouterLink>
      </FlexContainer>
      <Divider />
      <NavBox>
        <List>
          {NAV_ITEMS.map((item, itemIndex) => {
            if (item.hideAnonymous && !userStore.IsAuthenticated) {
              return null;
            }
            if (item.hideLoggedIn && userStore.IsAuthenticated) {
              return null;
            }
            if (item.requireLogin) {
              return userStore.IsAuthenticated ? (
                <NavbarItem handleNavbarClose={utilityStore.closeDrawer} key={itemIndex} item={item} />
              ) : (
                <AuthBarItem handleNavbarClose={utilityStore.closeDrawer} key={itemIndex} item={item} />
              );
            }

            return item.hideDesktop ? (
              <HiddenTabletUp key={itemIndex}>
                <SignInItem handleNavbarClose={utilityStore.closeDrawer} item={item} />
              </HiddenTabletUp>
            ) : (
              <NavbarItem handleNavbarClose={utilityStore.closeDrawer} key={itemIndex} item={item} />
            );
          })}
        </List>
        <FlexContainer column>
          <NavAds position={2} />
          <NavAds position={3} />
          <NavAds position={4} />
        </FlexContainer>
        <ButtonContainer>
          <HiddenDesktopUp>
            <CloseButton onClick={utilityStore.closeDrawer}>Close</CloseButton>
          </HiddenDesktopUp>
        </ButtonContainer>
      </NavBox>
    </DrawerContainer>
  );

  return (
    <>
      <HiddenDesktopDown>
        <Drawer>
          {content}
        </Drawer>
      </HiddenDesktopDown>
    </>
  );
}

export default observer(Navbar);
