import {faHome, faInfoCircle, faUser, faStoreAlt , faChartBar, faListAlt, faCloudDownloadAlt, faGavel, faLock} from '@fortawesome/free-solid-svg-icons'
import { siteUrls } from '../siteUrls';

const NAV_ITEMS = [
  {
    title: 'HOME',
    icon: faHome,
    link: siteUrls.HomeUrl
  },
  {
    title: 'ABOUT US',
    icon: faInfoCircle,
    link: siteUrls.AboutUsUrl
  },
  {
    title: 'CONTACT US',
    icon: faUser,
    link: siteUrls.AgentRepsUrl
  },
  {
    title: 'LISTING MARKETS',
    icon: faStoreAlt,
    link: siteUrls.ListingMarketsUrl,
    hash: true
  },
  {
    title: 'MARKET REPORTS',
    icon: faChartBar,
    hideAnonymous: false,
    link: siteUrls.MarketReportUrl,
    requireLogin: true
  },
  {
    title: 'ALL SALE LISTING',
    icon: faListAlt,
    link: siteUrls.ExtSalesListUrl
  },
  {
    title: 'SUPPORT / DEMO',
    icon: faCloudDownloadAlt,
    link: siteUrls.SupportUrl
  },
  {
    title: 'BUYER APPROVAL',
    icon: faGavel,
    link: siteUrls.BuyerApprovalUrl,
    hideAnonymous: true,
    highlight: true
  },
  {
    title: 'Login',
    icon: faLock,
    link: '/sign-in',
    hideLoggedIn: true,
    hideDesktop: true,
    isLogin: true
  }
];

export { NAV_ITEMS };
