import styled from "src/utils/styled/styled";


export const List = styled.ul`
    padding: 0;
    margin: 0;
    padding-top: 8px;
    padding-bottom: 10px;
    position: relative;
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
`;