import { observer } from 'mobx-react-lite';
import React from 'react';
import { FlexContainer } from 'src/components/base/container';
import SaleListingModel from 'src/models/SalesListings/saleListingModel';
import { ActiveAuctionButton, ActiveAuctionDescription } from './ActiveAuctionComponents';
import ActiveAuctionsMobile from './ActiveAuctionMobile';
import { BorderBox } from '../styledComponents';
import { AuctionColorPicker } from 'src/common/AuctionColorPicker';
import {
  HiddenMobileUp,
  HiddenTabletDown,
  HiddenTabletUpMobileDown
} from 'src/components/base/hidden';
import { DateContainer, TypeContainer, LinkCard } from './styledcomponents';
import { AuctionGradientPicker } from 'src/common/AuctionColorPicker';
import { formatDate, formatTime } from 'src/common/formatters';
import AuctionType from 'src/enums/sales/AuctionType';
import { LegacyApiUrls } from 'src/constants/legacyApiUrls';
import { useUtilityStore, useUserStore } from 'src/stores/useMobxStores';
import SignInModal from 'src/components/common/LoginModal/Login/SignInModal';


interface IActiveAuctionDisplay {
  sale: SaleListingModel;
}

const ActiveAuctionDisplay: React.FC<IActiveAuctionDisplay> = ({ sale }: IActiveAuctionDisplay) => {
  var SaleDate = new Date(sale.auctionDate);
  var currentDate = new Date();
   const userStore = useUserStore();
   const utilityStore = useUtilityStore();
  const [showDate, setShowDate] = React.useState<boolean>(true);
  React.useEffect(() => {
    if (sale.auctionType === AuctionType.Timed) {
      setShowDate(currentDate.getTime() < SaleDate.getTime());
    }
  }, [showDate]);

 
  // ) : userStore.IsAuthenticated ? (
  //   <ButtonRedirect saleType={sale.auctionType} auctionId={sale.auctionId} externalLink={sale.externalSaleLink} />

  const onCardClick = () => {
     let url = '';
     switch (sale.auctionType) {
       case AuctionType.Event:
       case AuctionType.None:
         url = `${LegacyApiUrls.GetBaseUrl()}/auctionnew/event?auctionId=${sale.auctionId}`;
         break;
       case AuctionType.Timed:
         url = sale.externalSaleLink;
         break;
       default:
         url = `${LegacyApiUrls.GetBaseUrl()}/auctionnew/?isBuyer=true&auctionId=${sale.auctionId}`;
         break;
     }
    if (sale.auctionType !== AuctionType.Timed && !userStore.IsAuthenticated) {
          utilityStore.openModal(<SignInModal />);
    }
    else {
      window.location.href=`${url}`
    }
  }


  if (!sale) return <></>;
  return (
    <LinkCard onClick={onCardClick}>
      <BorderBox color={AuctionColorPicker(sale.auctionType)}>
        <HiddenTabletDown>
          <FlexContainer gap={'10px'}>
            <FlexContainer columnGap={'10px'}>
              <ActiveAuctionButton sale={sale} />
              <ActiveAuctionDescription sale={sale} />
            </FlexContainer>
            <FlexContainer justifyContent={'flex-end'}>
              <DateContainer>
                {showDate && (
                  <div>
                    {formatDate(sale.auctionDate)} {formatTime(sale.auctionDate)} {sale.timeZone.replace('S', '')}
                  </div>
                )}
              </DateContainer>
              <TypeContainer color={AuctionGradientPicker(sale.auctionType)}>{sale.auctionTypeDisplay}</TypeContainer>
            </FlexContainer>
          </FlexContainer>
        </HiddenTabletDown>
        <HiddenTabletUpMobileDown>
          <FlexContainer gap={'10px'} column onClick={onCardClick}>
            <FlexContainer justifyContent={'space-between'}>
              <div style={{ width: '200px' }}>
                <ActiveAuctionButton sale={sale} />
              </div>
              <TypeContainer color={AuctionGradientPicker(sale.auctionType)}>{sale.auctionTypeDisplay}</TypeContainer>
            </FlexContainer>
            <FlexContainer columnGap={'10px'}>
              <ActiveAuctionDescription sale={sale} />
            </FlexContainer>
          </FlexContainer>
        </HiddenTabletUpMobileDown>
        <HiddenMobileUp>
          <ActiveAuctionsMobile sale={sale} />
        </HiddenMobileUp>
      </BorderBox>
    </LinkCard>
  );
}

export default observer(ActiveAuctionDisplay);
