import { Spinner, SpinnerSize } from '@fluentui/react';
import React from 'react';
import styled from 'src/utils/styled/styled';


const LoadingSpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const LoadingSpinner = () => {
  return (
    <LoadingSpinnerContainer>
      <Spinner size={SpinnerSize.large} />
    </LoadingSpinnerContainer>
  );
};

export default LoadingSpinner;
