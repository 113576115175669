import { makeObservable, observable } from 'mobx';
import FormModel from '../forms/FormModel';

export class SaleTypeModel extends FormModel {
  constructor() {
    super();
    makeObservable(this);
  }
  @observable
  public saleTypeDescription: string;
  @observable
  public saleTypeId: number;
  
}
