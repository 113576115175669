import { makeObservable, observable, runInAction } from 'mobx';
import LoginModel from 'src/models/account/LoginModel';
import ProfileModel from 'src/models/account/ProfileModel';
import LoginService from 'src/services/User/LoginService';

class UserStore {
  constructor() {
    makeObservable(this);
    this.User = new ProfileModel();
  }
  @observable
  IsAuthenticated: boolean = false;
  @observable
  User: ProfileModel;
  @observable
  loading: boolean = false;

  get isLoggedIn() {
    return this.IsAuthenticated;
  }

  public Login = async (user: LoginModel) => {
    const service = new LoginService();
    const response = await service.login(user);
    if (response.data.succeeded) {
      const legacyResponse = await service.legacyLogin(user);
      if (legacyResponse.data.username === null) {
        console.log('legacy login failure');
      }
      this.InitLogin(true);
      return true;
    }
    return false;
  };

  public Logout = async () => {
    this.loading = true;
    const service = new LoginService();
    const response = await service.logout();
    runInAction(() => {
      this.IsAuthenticated = false;
      this.User = null;
      this.loading = false;
    });
  };

  public InitLogin = async (isLogin = false) => {
    this.loading = true;
    try {
      const service = new LoginService();
      var usermodel = new ProfileModel();
      const response = await service.getLoggedIn();
      usermodel.SetData(response.data);
      var legacyResponse = await service.legacyLoginCheck();
      if (!legacyResponse.data && !isLogin && window.location.hostname.indexOf('local') < 0) {
        this.Logout();
        return null;
      } else {
        runInAction(() => {
          this.User = new ProfileModel();
          this.User.SetData(usermodel);
          this.IsAuthenticated = true;
          this.loading = false;
        });
        return usermodel;
      }
    } catch (error) {
      runInAction(() => (this.loading = false));
    }
  };
}
export default UserStore;
