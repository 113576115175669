import { action, makeObservable, observable } from "mobx";
import { IUserName, IEmail, IFirstName, ILastName, IBusinessName, ICity, IPostalCode, IUserId, IWorkPhone, ICellPhone, IEmailList, IAddress, ICountry, IProvince } from "src/interfaces/Users/RegisterInterfaces";
import FormModel from "../forms/FormModel";


class ProfileModel
  extends FormModel
  implements
    IUserName,
    IEmail,
    IFirstName,
    ILastName,
    IBusinessName,
    ICity,
    IPostalCode,
    IUserId,
    IWorkPhone,
    ICellPhone,
    IEmailList,
    IAddress,
    ICountry,
    IProvince
{
  constructor(emailList?: boolean) {
    super();
    this.roles = new Array<string>();
    this.emailList = emailList;
    makeObservable(this);
  }
  @observable
  public userName: string;
  @observable
  public email: string;
  @observable
  public firstName: string;
  @observable
  public lastName: string;
  @observable
  public businessName: string;
  @observable
  public userId: number;
  @observable
  public city: string;
  @observable
  public postalCode: string;
  @observable
  public workPhone: string;
  @observable
  public cellPhone: string;
  @observable
  public emailList: boolean;
  @observable
  public signInCount: number;
  @observable
  public lastLogin: string;
  @observable
  public roles: Array<string>;
  @observable
  public address: string;
  @observable
  public country: string;
  @observable
  public province: string;
  @observable
  public dateCreated: string;
  @observable
  public approved: boolean;
  @observable
  public verifiedEmail: boolean;
  @observable
  public password: string;
  @observable
  public confirmPassword: string;
  @observable
  public policy: boolean;
  @observable
  public notARobot: boolean;
  @action
  OnSetCountry = (country: string) => {
    this.country = country;
  };
}

export default ProfileModel