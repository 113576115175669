import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useUtilityStore } from 'src/stores/useMobxStores';
import SignInModal from '../../LoginModal/Login/SignInModal';

import { ListItem, Button, Box, Title, FakeLink } from './styledComponents';

interface props {
  item: any;
  handleNavbarClose: () => void;
}

export const AuthBarItem = ({ item, handleNavbarClose }: props) => {
 
    const utilityStore = useUtilityStore();
  const { highlight, icon: Icon, title, link } = item;
  const handleClickItem = () => {
    utilityStore.openModal(<SignInModal link={link} />);
    handleNavbarClose();
  };
  let style = {};
  if (highlight) {
    style = {
      color: '#139d45',
      fontWeight: 'bold'
    };
  }

  return (
    <ListItem>
      <Button onClick={handleClickItem}>
        <FakeLink>
          <Box>{Icon && <FontAwesomeIcon icon={Icon} style={style} />}</Box>
          <Title style={style}>{title}</Title>
        </FakeLink>
      </Button>
    </ListItem>
  );
};
