import { BannerModel } from 'src/models/admin/BannerModel';
import ServiceBase from '../Common/ServiceBase';

class BannerService extends ServiceBase {
  public getBanners = async (active: boolean) => {
    return await this.get<Array<BannerModel>>(`/banner/${active}`);
  };
  public uploadBanner = async (bannerModel: BannerModel) => {
    return await this.post<boolean>(`/banner`, bannerModel);
  };
  public deleteBanner = async (id: number) => {
    return await this.delete<void>(`/banner/${id}`);
  };
  public toggleActive = async (id: number) => {
    return await this.put<void>(`/banner/${id}`);
  };
}

export default BannerService;
