import { device, deviceUp } from 'src/constants/themes';
import styled from 'src/utils/styled/styled';



export const HiddenTabletDown = styled.div`
  @media ${device.tablet} {
    display: none;
  }
`;
export const HiddenTabletUp = styled.div`
  @media ${deviceUp.tablet} {
    display: none;
  }
`;
export const HiddenDesktopDown = styled.div`
  @media ${device.desktop} {
    display: none;
  }
`;
export const HiddenMobileDown = styled.div`
  @media ${device.mobileM} {
    display: none;
  }
`;
export const HiddenTabletUpMobileDown = styled.div`
  @media ${deviceUp.tablet} {
    display: none;
  }
  @media ${device.mobileM} {
    display: none;
  }
`;
export const HiddenMobileUp = styled.div`
  @media ${deviceUp.mobileM} {
    display: none;
  }
`;
export const HiddenDesktopUp = styled.div`
  @media ${deviceUp.desktop} {
    display: none;
  }
`;