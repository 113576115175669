import { makeObservable, observable } from 'mobx';
import FormModel from '../forms/FormModel';

export class LiveStreamModel extends FormModel {
  constructor() {
    super();
    makeObservable(this);
  }
  @observable
  public companyId: number;
  @observable
  public liveStream: string;
}
