import { observer } from 'mobx-react-lite';
import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Header from 'src/components/common/Header/Header';
import Navbar from 'src/components/common/Navbar/Navbar';
import { Content, Root, Nav } from './styledComponents';

const ProfileLayout: React.FC = (props) => {
  return (
    <Root>
      <Nav>
        <Navbar />
      </Nav>
      <Header />
      <Content>{props.children}</Content>
    </Root>
  );
};

export default observer(ProfileLayout);
