import React from 'react';

class LoadingComponent {
  public static GetLoadingComponent(Component: React.LazyExoticComponent<any>, additionalProps?: any) {
    return (props: any) => (
      <React.Suspense fallback={<div />}>
        <Component {...props} {...additionalProps} />
      </React.Suspense>
    );
  }
}

export default LoadingComponent;
