import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FlexContainer, LargePaddedBox } from 'src/components/base/container';
import { HiddenMobileDown, HiddenMobileUp } from 'src/components/base/hidden';
import MultipleAds from 'src/components/common/Ads/MultipleAds';
import { siteUrls } from 'src/constants/siteUrls';
import AuctionStatus from 'src/enums/sales/AuctionStatus';
import AuctionType from 'src/enums/sales/AuctionType';
import SaleListingModel from 'src/models/SalesListings/saleListingModel';
import { useSaleListStore } from 'src/stores/useMobxStores';
import styled from 'src/utils/styled/styled';
import ActiveAuctionsList from './components/ActiveAuctionsList/ActiveAuctionsList';
import AuctionsCarousel from './components/AuctionsCarousel/AuctionsCarousel';
import { BannerButton } from './components/styledComponents';
import UpcomingAuctionsList from './components/UpcomingAuctionsList/UpcomingAuctionsList';

const Box = styled.div`
  margin-bottom: 20px;
`;

function ActiveAuctions() {
  const saleListStore = useSaleListStore();
  const [upcomingAuctions, setUpcomingAuctions] = useState(new Array<SaleListingModel>());
  const [nonTimedAuctions, setNonTimedAuctions] = useState(new Array<SaleListingModel>());
  const [timedAuctions, setTimedAuctions] = useState(new Array<SaleListingModel>());
  const [registrationId, setRegistrationId] = useState(-1);

  useEffect(() => {
    saleListStore.registerForUpdate(setDataModels).then((number) => {
      setRegistrationId(number);
    });
    // authenticationService.Login('cksoftware', '4ort2n@te');
    return () => {
      saleListStore.deregisterForUpdate(registrationId);
    };
  }, [saleListStore]);

  const setDataModels = (auctionModels: Array<SaleListingModel>) => {
    console.log('setting data models');

    setNonTimedAuctions(
      auctionModels.filter(
        (value) => value.auctionType !== AuctionType.Timed && value.auctionStatus === AuctionStatus.Selling
      )
    );
    setTimedAuctions(
      auctionModels.filter(
        (value) => value.auctionType === AuctionType.Timed && value.auctionStatus === AuctionStatus.Selling
      )
    );
    setUpcomingAuctions(auctionModels.filter((value) => value.auctionStatus === AuctionStatus.Consigning));
  };

  return (
    <FlexContainer column>
      <AuctionsCarousel />
      <div style={{ padding: '10px' }}>
        <Box>
          <ActiveAuctionsList rows={nonTimedAuctions} title={'Sales and Events In-Progress'} isTimedSale={false} />
        </Box>
        <Box>
          <ActiveAuctionsList rows={timedAuctions} title={'FarmGate Timed Auctions Bidding Open'} isTimedSale={true} />
        </Box>
      </div>

      <MultipleAds position={1} />
      <div style={{ padding: '10px' }}>
        <UpcomingAuctionsList rows={upcomingAuctions} title={'Upcoming Sales and Events'} />
        <LargePaddedBox>
          <FlexContainer justifyContent='center'>
            <NavLink to={siteUrls.ExtSalesListUrl}>
              <HiddenMobileDown>
                <BannerButton>Click Here to view the DLMS Extended Sale Listing Page</BannerButton>
              </HiddenMobileDown>
              <HiddenMobileUp>
                <BannerButton>DLMS Extended Sale Listing Page</BannerButton>
              </HiddenMobileUp>
            </NavLink>
          </FlexContainer>
        </LargePaddedBox>
      </div>
      <MultipleAds position={1} />
    </FlexContainer>
  );
}

export default observer(ActiveAuctions);
