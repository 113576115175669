import styled from 'src/utils/styled/styled';
import { IColor, IInfoButton } from 'src/interfaces/common/styledComponents';
import { device, siteColors } from 'src/constants/themes';
import { Link } from 'react-router-dom';

export const BorderBox = styled.div<IColor>`
  border-top: 2px solid ${(props) => props.color};
  margin-top: 10px;
  padding: 10px 0px 10px 10px;
`;

export const Button = styled.button`
  text-transform: uppercase;
  height: 36px;
  width: max-content;
  color: white;
  font-size: '0.875rem';
  font-weight: 500;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  box-shadow: 0px 5px 5px #666;
  padding: 6px 16px;
  border: 0px;
  border-radius: 4px;
  transition: box-shadow 0.3s;
  &:hover {
    cursor: pointer;
    box-shadow: none;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: grey;
    box-shadow: none;
  }
`;

export const InfoButton = styled(Button)<IInfoButton>`
  background-color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize ?? '0.875rem'};
  &:hover {
    background-color: ${(props) => props.darkColor};
    
  }
`;

export const SuccessButton = styled(Button)`
  background-color: ${siteColors.success};
  &:hover {
    background-color: ${siteColors.successHover};
    
  }
`;
export const PrimaryButton = styled(Button)`
  background-color: ${siteColors.primary};
  &:hover {
    background-color: ${siteColors.primaryHover};
    
  }
`;
export const CancelButton = styled(Button)`
  background-color: ${siteColors.link};
  &:hover {
    background-color: ${siteColors.linkHover};
    
  }
`;

export const BannerButton = styled(Button)`
  background-color: ${siteColors.primary};
  font-size: 20px;
  &:hover {
    background-color: ${siteColors.primaryHover};
    
  }
  @media ${device.tablet} {
    font-size: 16px;
  }
  @media ${device.mobileM} {
    font-size: 14px;
  }
`;

export const DateType = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  @media ${device.tablet} {
    flex-direction: column-reverse;
    align-items: flex-end;
    row-gap: 5px;
  }
  @media ${device.mobileM} {
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
  } ;
`;

export const TypeContainer = styled.div<IColor>`
  background-image: linear-gradient(to right, ${(props) => props.color});
  color: white;
  margin-top: -10px;
  width: 200px;
  max-height: 46px;
  text-align: center;
  font-size: 1rem;
  padding: 12px 0px;
`;

export const DateContainer = styled.div`
  font-size: 1rem;
  font-weight: 500;
  width: max-content;
  margin-right: 10px;
  @media ${device.tablet} {
    font-size: .8rem;
    margin-right: auto;
  }
`;

export const TypeLink = styled.a`
  margin-top: -2px;
  width: 50%;
  @media ${device.mobileM} {
    width: 60%;
  }
`;

export const TypeDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const BidderLink = styled(Link)`
  color: #08a000;
  margin-right: auto;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

export const CustomerTextNoImage = styled.div`
      display: flex;
      width: 100%;
      gap: 10px;
      padding-top: 4px;
      flex-wrap: wrap;
`;


interface ILink {
  underline?: boolean;
}

export const LinkBox = styled.a<ILink>`
  color: black;
  font-size: 1rem;
  &:hover {
    text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
  }
`;

export const Image = styled.img`
    height: auto;
    width: 100%;
    border-radius: 5px;
`;

export const TabletLinkBox = styled.a`
  color: black;
  font-size: .91rem;
  text-decoration: none;
  margin-right: auto;
`;

export const AuctionDescription = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  margin-top: 10px;
  @media ${device.mobileM} {
    font-size: 0.8rem;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: auto;
  max-width: 600px;
  display: flex;
  justify-content: flex-start;
  @media ${device.mobileM} {
    width: 98%;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;
  }
`;
interface IDirection {
  direction: string;
}
export const TextBox = styled.div<IDirection>`
  width: max-content;
  display: flex;
  flex-direction: ${props => props.direction};
  margin-right: auto;
  row-gap: 5px;
  column-gap: 10px;
`;


