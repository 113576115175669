import App from '@/app/App';
import { restoreSettings } from '@/utils/settings/settings';
import { SettingsProvider } from '@/utils/settings/settingsContext';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import Elmahio from 'elmah.io.javascript';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';


new Elmahio({
  apiKey: '42f2b069361c44179fc7b7902233d09c',
  logId: '3eefafe0-d5a0-426f-b611-a8cef438be03'
});

const settings = restoreSettings();
initializeIcons();
ReactDOM.render(
  <StrictMode>
    <SettingsProvider settings={settings}>
        <App />
    </SettingsProvider>,
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
