import React, { useState } from 'react';
import SaleListingModel from 'src/models/SalesListings/saleListingModel';
import { FlexContainer, Image } from 'src/components/base/container';
import { observer } from 'mobx-react-lite';
import { siteUrls } from 'src/constants/siteUrls';
import { BidderLink, PrimaryButton, TextBox } from '../styledComponents';
import { ModalContainer } from 'src/components/common/LoginModal/Login/styledComponents';
import { Divider, EmphasizedTextDisplay, StyledLink } from 'src/components/base/styledComponents';
import AuctionType from 'src/enums/sales/AuctionType';
import { useUserStore, useUtilityStore } from 'src/stores/useMobxStores';
import { ButtonLink, ImageContainer, ModalCard } from './styledComponents';

interface props {
  sale: SaleListingModel;
}


const AuctionModal = ({ sale }: props) => {
  const [isImage, setIsImage] = useState<boolean>(!!sale.promotionalImage);
  const userStore = useUserStore();
  const utilityStore = useUtilityStore();
  return (
    <ModalContainer>
      <ModalCard>
        {isImage && (
          <ImageContainer>
            <Image
              alt='promo'
              onError={() => setIsImage(false)}
              src={siteUrls.GetDlmsPromoImageUrl(sale.promotionalImage)}
            />
          </ImageContainer>
        )}
        <EmphasizedTextDisplay>{sale.auctionName} </EmphasizedTextDisplay>
        <FlexContainer column>
          <p>{sale.auctionDescription}</p>
          <p>Customer Information</p>
          <Divider />
          <FlexContainer column>
            <TextBox direction={'column'}>
              {userStore.isLoggedIn && sale.auctionType === AuctionType.Purebred && (
                <BidderLink onClick={utilityStore.closeModal} to={`/Info/BuyerApproval`}>
                  Buyer Approval
                </BidderLink>
              )}
              {sale.customerLinkText && (
                <StyledLink target='_blank' href={sale.customerLinkUrl}>
                  {sale.customerLinkText}
                </StyledLink>
              )}

              {sale.saleCatalogueText && (
                <StyledLink target='_blank' href={sale.saleCatalogueUrl}>
                  {sale.saleCatalogueText}
                </StyledLink>
              )}
              {sale.videoText && (
                <StyledLink target='_blank' href={sale.videoUrl}>
                  {sale.videoText}
                </StyledLink>
              )}
              {sale.infoDocumentText && (
                <StyledLink target='_blank' href={`https://dlms.ca/Media/Document/${sale.infoDocument}`}>
                  {sale.infoDocumentText}
                </StyledLink>
              )}
            </TextBox>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer justifyContent={'center'}>
          {sale.lotDetails > 0 && (
            <PrimaryButton>
              <ButtonLink href={siteUrls.GetAuctionLotListUrl(sale.auctionId)}>View Lot Details</ButtonLink>
            </PrimaryButton>
          )}
        </FlexContainer>
      </ModalCard>
    </ModalContainer>
  );
};

export default observer(AuctionModal);
