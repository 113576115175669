import * as React from 'react';

interface IErrorBoundary {
  type?: 'element' | 'section' | 'app';
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: any;
}

class ErrorBoundary extends React.Component<IErrorBoundary, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.    
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    console.error('Error Boundary Caught:', error, info);
  }

  componentWillUnmount(): void {
    this.state = { hasError: false, error: null }
  }

  render() {
    const { error, hasError } = this.state
    if (hasError) {
      // You can render any custom fallback UI      
      return <div>
        <div style={{ 'width': '100%', 'paddingTop': '20px', 'textAlign': 'center' }}>
          {error.name === 'ChunkLoadError' ?
            <div>
              This application has been updated, please <a href="#" onClick={() => { window.location.reload(); }}>refresh</a> your browser.
            </div>
            :
            <div>
              An error has occurred, please <a href="#" onClick={() => { window.location.reload(); }}>refresh</a> and try again.
            </div>}
        </div>
      </div>
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
