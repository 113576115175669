import dayjs from 'dayjs';
import { stringIsNullOrEmpty } from '../utils/helpers/stringHelpers';

const formatters = {
  momentDate: 'ddd DD-MMM YYYY',
  momentDateTime: 'M/D/YYYY h:mm A',
  momentTime: 'h:mm A ',
  momentDateNoYear: 'DD MMM',
  momentDayName: 'dddd MMM D YYYY h:mm A',
  momentDayNameNoTime: 'dddd MMM D YYYY',
  momentMonthDayYear: 'MMM DD, YYYY',
  momentDayMonthYear: 'DD/MMM/YYYY',
  momentFullDayMonthYear: 'dddd, MMMM D, YYYY'
};

export const formatDate = (date: string) => {
  if (stringIsNullOrEmpty(date)) {
    return '';
  }
  return dayjs(date, formatters.momentDate).format(formatters.momentDate);
};

export const formatFullDate = (date: string) => {
  if (stringIsNullOrEmpty(date)) {
    return '';
  }
  return dayjs(date, formatters.momentDayName).format(formatters.momentDayName);
};
export const formatFullDateNoTIme = (date: string) => {
  if (stringIsNullOrEmpty(date)) {
    return '';
  }
  return dayjs(date, formatters.momentDayNameNoTime).format(formatters.momentDayNameNoTime);
};

export const formatDateTime = (date: string) => {
  if (stringIsNullOrEmpty(date)) {
    return '';
  }
  return dayjs(date, formatters.momentDate).format(formatters.momentDateTime);
};

export const formatTime = (date: string) => {
  if (stringIsNullOrEmpty(date)) {
    return '';
  }
  return dayjs(date, formatters.momentDate).format(formatters.momentTime);
};

export const formatDateNoYear = (date: string) => {
  if (stringIsNullOrEmpty(date)) {
    return '';
  }
  return dayjs(date, formatters.momentDate).format(formatters.momentDateNoYear);
};

export const formatMonthDayYear = (date: string) => {
  if (stringIsNullOrEmpty(date)) {
    return '';
  }
  return dayjs(date, formatters.momentDate).format(formatters.momentMonthDayYear);
};
export const formatDayMonthYear = (date: string) => {
  if (stringIsNullOrEmpty(date)) {
    return '';
  }
  return dayjs(date, formatters.momentDate).format(formatters.momentDayMonthYear);
};
export const formatFullDayMonthYear = (date: string) => {
  if (stringIsNullOrEmpty(date)) {
    return '';
  }
  return dayjs(date, formatters.momentDate).format(formatters.momentFullDayMonthYear);
};

export default formatters;
