import { makeObservable, observable } from 'mobx';
import FormModel from '../forms/FormModel';

class LoginModel extends FormModel  {
  constructor() {
    super()
    makeObservable(this);
  }

  @observable
  public password: string;

  @observable
  public username: string;

  @observable
  public RememberMe: boolean = true;

}

export default LoginModel;
