import { observer } from 'mobx-react-lite';
import React from 'react';
import { FlexContainer } from 'src/components/base/container';
import SaleListingModel from 'src/models/SalesListings/saleListingModel';
import AuctionType from 'src/enums/sales/AuctionType';
import { useUtilityStore } from 'src/stores/useMobxStores';
import AuctionModal from '../AuctionsModal/AuctionsModal';
import DisplayIcons from 'src/components/common/LinkDisplayIcons/DisplayIcons';
import { useHistory } from 'react-router';
import { siteUrls } from 'src/constants/siteUrls';
import { BorderBox, DateContainer, DateType, InfoButton } from '../styledComponents';
import { AuctionColorPicker, AuctionColorPickerDark, AuctionGradientPicker } from 'src/common/AuctionColorPicker';
import { formatDate, formatTime } from 'src/common/formatters';
import { EmphasizedTextDisplay } from 'src/components/base/styledComponents';
import { MobileTypeContainer } from '../ActiveAuctionsList/styledcomponents';
import { ImageDisplay } from './UpcomingAuctionComponents';

interface IUpcomingAuctionMobile {
  sale: SaleListingModel;
  setIsImage: (value: boolean) => void;
  isImage: boolean;
}

const UpcomingAuctionMobile = ({ sale, setIsImage, isImage }: IUpcomingAuctionMobile) => {
  const history = useHistory();
  const utilityStore = useUtilityStore();


  const showMobileAction = (sale: SaleListingModel) => {
    switch (sale.auctionType) {
      case AuctionType.Event:
      case AuctionType.Purebred:
        utilityStore.openModal(<AuctionModal sale={sale} />);
        break;
      case AuctionType.Timed:
        window.location.href = sale.externalSaleLink;
        break;
      default:
        history.push(siteUrls.GetAuctionLotListUrl(sale.auctionId));
        break;
    }
  };

  return (
    <BorderBox color={AuctionColorPicker(sale.auctionType)}>
      <FlexContainer column flexWrap={'wrap'} onClick={() => showMobileAction(sale)}>
        <DateType>
          <DateContainer>
            {formatDate(sale.auctionDate)} {formatTime(sale.auctionDate)} {sale.timeZone.replace('S', '')}
          </DateContainer>
          <MobileTypeContainer color={AuctionGradientPicker(sale.auctionType)}>
            {sale.auctionTypeDisplay}
          </MobileTypeContainer>
        </DateType>
        <FlexContainer column justifyContent={'flex-start'}>
          <EmphasizedTextDisplay>{sale.auctionName}</EmphasizedTextDisplay>
        </FlexContainer>
        {isImage && <ImageDisplay sale={sale} setIsImage={setIsImage} />}
        <FlexContainer rowGap={'5px'} justifyContent={'space-between'} flexWrap={'wrap'}>
          <InfoButton color={AuctionColorPicker(sale.auctionType)} darkColor={AuctionColorPickerDark(sale.auctionType)}>
            Sale Information
          </InfoButton>
          <DisplayIcons sale={sale} />
        </FlexContainer>
      </FlexContainer>
    </BorderBox>
  );
};

export default observer(UpcomingAuctionMobile);
