import '@/styles/index.scss';
import useSettings from '@/utils/settings/useSettings';
import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs/plugin/utc';
import { observer } from 'mobx-react-lite';
import 'mobx-react-lite/batchingForReactDom';
import React, { useEffect } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useUserStore } from 'src/stores/useMobxStores';

import Routes from './routes';

//This adds support for UTC and local timezone conversions to dayjs
dayjs.extend(dayjsPluginUTC);

// const ToastContainer = React.lazy(() => import('react-toastify'));
const App = () => {
  const userStore = useUserStore();
  const { InitLogin } = userStore;
  let { settings } = useSettings();
  settings = {
    ...settings,
    theme: 'LIGHT'
  };

  useEffect(() => {
    InitLogin();
  }, [InitLogin]);

  return <Routes />;
};

export default observer(App);
