import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { useUtilityStore } from 'src/stores/useMobxStores';
import { FlexContainer, SmallPaddedBox } from 'src/components/base/container';
import { ModalCard, ModalContainer, ModalTitle, ModalSubTitle, Lock, TitleBox } from './styledComponents';
import { Divider } from 'src/components/base/styledComponents';
import { RouteLink } from '../../base/RouteLink';
import { faUserLock } from '@fortawesome/free-solid-svg-icons';
import SignInForm from './SignInForm/SignInForm';

interface props {
  link?: string;
}



function SignInModal({ link }: props) {
  const utilityStore = useUtilityStore();

  return (
    <ModalContainer>
      <ModalCard>
        <Lock>
          <FontAwesomeIcon style={{ height: '100%', width: '100%' }} icon={faUserLock} />
        </Lock>
        <ModalTitle>Sign in</ModalTitle>
        <ModalSubTitle>Sign in your account</ModalSubTitle>
        <TitleBox>
          <SignInForm link={link} />
        </TitleBox>
        <SmallPaddedBox>
          <Divider />
        </SmallPaddedBox>
        <FlexContainer column alignItems={'center'}>
          <RouteLink onClick={utilityStore.closeModal} to={'/Account/register'}>
            Create new account
          </RouteLink>
          <RouteLink onClick={utilityStore.closeModal} to={'/Account/LostPassword'}>
            Forgot Password?
          </RouteLink>
        </FlexContainer>
      </ModalCard>
    </ModalContainer>
  );
}

export default observer(SignInModal);
