import { runInAction } from 'mobx';
import ResponseModel from 'src/models/account/ResponseModel';
import { CompanyContactModel } from 'src/models/admin/CompanyContactModel';
import AdminStore from 'src/stores/AdminStore';



import ServiceBase from '../Common/ServiceBase';

class CompanyContactService extends ServiceBase {
  public getContactsInfo = async (store: AdminStore) => {
    store.loading = true;
    const response = await this.get<Array<CompanyContactModel>>(`/companycontact/${store.searchId}`);
    runInAction(() => {
      const list = new Array<CompanyContactModel>();
      response.data.map((value) => {
        const company = new CompanyContactModel();
        company.SetData(value);
        list.push(value);
      });
      store.companyContacts.clear();
      store.companyContacts.replace(list);
      store.loading = false;
    });
  };

  public updateContactInfo = async (contact: CompanyContactModel) => {
    const response = await this.put<ResponseModel>(`/companycontact/update`, contact);
    return response;
  };
  public createContactInfo = async (contact: CompanyContactModel) => {
    return await this.post<ResponseModel>(`/companycontact`, contact);
  };
  public deleteContactInfo = async (id: number) => {
    return await this.delete(`/companycontact/${id}`);
  };
}

export default CompanyContactService;
