import template from 'lodash/template';
import templateSettings from 'lodash/templateSettings';
import Endpoint from '../../services/Common/Endpoint';

export const stringIsNullOrEmpty = (value?: string | null): value is undefined => {
  return value === undefined || value === null || value === '';
};

export const isFirstCharacterVowel = (property: string) => {
  const firstCharacter = property[0];
  return /[aeiouAEIOU]/.test(firstCharacter);
};

export const transformTemplate = (input: string, options?: object) => {
  templateSettings.interpolate = /{{([\s\S]+?)}}/g;
  const compiled = template(input);
  return compiled(options);
};

export const spaceCapitalizedString = (input: string) => {
  input = input.replace(/([A-Z])/g, ' $1').trim();
  return input;
};

export const getFormattedUrl = (endpoint: Endpoint, params?: object) => {
  let additionalQueryParams = '';
  if (params) {
    //Loop through all params in params
    additionalQueryParams = Object.keys(params)
      .map((key) => {
        const value = params[key];
        if (value === null || value === undefined) {
          //If the param is null or undefined, we do not want to add it to the query string
          return null;
        }
        //If the param is in the template, we ignore it. We only want to create a list of optional/additional params
        if (endpoint.endpointUrl.indexOf('{{' + key + '}}') < 0) {
          return key + '=' + encodeURIComponent(value);
        }
        return null;
      })
      //this filter will remove all null/false string objects from the array
      .filter(Boolean)
      .join('&');
  }

  let transformedUrl = `${transformTemplate(endpoint.endpointUrl, params)}`;
  if (!stringIsNullOrEmpty(additionalQueryParams)) {
    if (transformedUrl.indexOf('?') < 0) {
      transformedUrl += '?';
    } else {
      transformedUrl += '&';
    }
    transformedUrl += additionalQueryParams;
  }
  return transformedUrl;
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};
