import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import FormWrapper from 'src/app/form/FormWrapper';
import { FlexContainer } from 'src/components/base/container';
import LoginPasswordField from 'src/components/forms/inputfields/account/login/LoginPasswordField';
import LoginUsernameField from 'src/components/forms/inputfields/account/login/LoginUsernameField';
import { siteColors } from 'src/constants/themes';
import { SuccessButton } from 'src/containers/ActiveAuctions/components/styledComponents';
import LoginModel from 'src/models/account/LoginModel';
import { useRouterStore, useUserStore, useUtilityStore } from 'src/stores/useMobxStores';
import styled from 'styled-components';

const ErrorBox = styled.div`
  max-width: 200px;
  color: ${siteColors.link};
`;

interface props {
  link?: string;
}

const SignInForm = ({ link }: props) => {
  const routerStore = useRouterStore();
  const userStore = useUserStore();
  const utilityStore = useUtilityStore();
  const [user] = useState(new LoginModel());
  const location = useLocation();
  const history = useHistory();
  const [error, setError] = useState<string>(null);

  const onSubmit = async () => {
    try {
      const response = await userStore.Login(user);
      if (response) {
        if (link) {
          routerStore.history.replace(link);
        }
        if (location.pathname === '/Account/Register') history.replace('/');
        if (history.location.search === '?url=%2FAccount%2FLostPassword') history.replace('/');
        setError(null);
        utilityStore.closeModal();
        toast.success('Login successful', { position: 'top-center', theme: 'colored' });
      } else {
        setError(
          "Your account has been created but requires email verification before you may log in. Please check your email for a verification link. Don't see the email? Please check your spam folder or contact us directly for assistance!"
        );
      }
    } catch (error) {
      setError('Login failed. Please check your username and password.');
      user.password = '';
    }
  };
  return (
    <FormWrapper onSubmit={onSubmit}>
      <FlexContainer column rowGap={'10px'} alignItems={'center'}>
        <LoginUsernameField model={user} />
        <LoginPasswordField model={user} />
        {error && <ErrorBox>{error}</ErrorBox>}
        <SuccessButton>Login</SuccessButton>
      </FlexContainer>
    </FormWrapper>
  );
};
export default observer(SignInForm);
