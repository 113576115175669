import styled from 'src/utils/styled/styled';
import { NavLink } from 'react-router-dom'


export const ListItem = styled.li`
  display: block;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
`;

export const Button = styled.button`
  background-color: white;
  border: none;
  width: 100%;
  
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  justify-content: flex-start;
`;

export const Link = styled(NavLink)`
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  gap: 10px;
  font-weight: 400;
  color: #546e7a;
  &:visited {
    color: #546e7a;
  }
`;

export const Title = styled.span`
  margin-right: auto;
`;

export const Box = styled.span`
  width: 50px;
`;
export const AdminBox = styled.span`
width: 25px;
`

export const FakeLink = styled.span`
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  gap: 10px;
  font-weight: 400;
  color: #546e7a;
`;