import {computed, IObservableArray, makeObservable, observable} from "mobx";
import FormModel from "src/models/forms/FormModel";
import {arraySetData} from "src/utils/helpers/arrayHelpers";
import FrameQualityModel from "./FrameQualityModel";
import HerdMakeUpModel from "./HerdMakeUpModel";
import LotMediaModel from "./LotMediaModel";
import PureBreedModel from "./PureBreedModel";
import {formatCurrency} from "@/utils/helpers/numberHelpers";

export default class LotDetailModel extends FormModel {
    constructor() {
        super();
        makeObservable(this);
        this.lotMedia = new Array<LotMediaModel>() as IObservableArray;
        this.herdMakeups = new Array<HerdMakeUpModel>() as IObservableArray;
        this.frameQualities = new Array<FrameQualityModel>() as IObservableArray;
        this.pureBreedDetails = new PureBreedModel();
    }

    public SetData(dbData: any): void {
        super.SetData(dbData, ['lotMedia, herdMakeups, frameQualities, pureBreedDetails']);
        arraySetData(this.lotMedia, LotMediaModel, dbData.lotMedia);
        arraySetData(this.herdMakeups, HerdMakeUpModel, dbData.herdMakeups);
        arraySetData(this.frameQualities, FrameQualityModel, dbData.frameQualities);
    }

    @computed
    public get buyerFeeHeader(): string {
        return this.buyerFeeHeaderOverride ? this.buyerFeeHeaderOverride : "Buyer Fee";
    }

    @computed
    public get buyerFeeDisplay(): string {
        if (this.buyerFeeType === "Text" && this.buyerFeeTextOverride){
            return this.buyerFeeTextOverride;
        }
        if (this.buyerFeeType === "Percentage" && this.buyerFee && this.buyerFee > 0){
            return this.buyerFee + "% of purchase price";
        }
        if (this.buyerFee && this.buyerFee > 0 && this.buyerFeeType !== "None"){
            return formatCurrency(this.buyerFee) + " / head";
        }
        return "None"
    }

    @computed
    public get clearingHeader(): string {
        return this.clearingHeaderOverride ? this.clearingHeaderOverride : "Payment Terms/Clearing:";
    }

    @computed
    public get clearingDisplay(): string {
        return this.clearingTextOverride ? this.clearingTextOverride : "Clearing applies to all purchases if payment isn’t received within 24 hours of invoicing.";
    }

    @computed
    public get transitHeader(): string {
        return  this.transitHeaderOverride ? this.transitHeaderOverride : "Transit Insurance:";
    }

    @computed
    public get transitDisplay(): string {
        return  this.transitTextOverride ? this.transitTextOverride : "Purchased cattle are not insured for transit for the buyer until insurance is requested.";
    }

    @observable
    public lotId: number;
    @observable
    public sequenceNumber: number;
    @observable
    public head: number;
    @observable
    public location: string;
    @observable
    public lotNumber: number;
    @observable
    public color: string;
    @observable
    public listingMarket: string;
    @observable
    public minWeight: number;
    @observable
    public clearing: string;
    @observable
    public yardage: string;
    @observable
    public yardageAmount: number | undefined;
    @observable
    public buyerFee: number;
    @observable
    public buyerFeeType: string;

    @observable
    public buyerFeeHeaderOverride: string;

    @observable
    public buyerFeeTextOverride: string;

    @observable
    public clearingHeaderOverride: string;

    @observable
    public clearingTextOverride: string;
    @observable
    public hasTransit: boolean | undefined;
    @observable
    public transitHeaderOverride: string;

    @observable
    public transitTextOverride: string;
    @observable
    public feedProgram: string;
    @observable
    public vetWork: string;
    @observable
    public weighingConditions: string;
    @observable
    public cutOutOptions: string;
    @observable
    public sex: string;
    @observable
    public avgWeight: number;
    @observable
    public totalWeight: number;
    @observable
    public penNo: string;
    @observable
    public breed: string;
    @observable
    public comments: string;
    @observable
    public videoId: string;
    @observable
    public videoType: string;
    @observable
    public fieldMan: string;
    @observable
    public ageVerified: string;
    @observable
    public bodyCondition: string;
    @observable
    public weightSlide: number;
    @observable
    public maxWeight: number;
    @observable
    public priceSlide: string;
    @observable
    public estimatedYield: number;
    @observable
    public slideType: string;
    @observable
    public estimatedGrade: string;
    @observable
    public deliveryDate: string;
    @observable
    public toDeliveryDate: string;
    @observable
    public consignorName: string;
    @observable
    public registerNumber: string;
    @observable
    public pureBreedName: string;
    @observable
    public pureBreedDetails: PureBreedModel;
    @observable
    public frameQualities: IObservableArray<FrameQualityModel>;
    @observable
    public herdMakeups: IObservableArray<HerdMakeUpModel>;
    @observable
    public soldPrice: number;
    @observable
    public lotMedia: IObservableArray<LotMediaModel>;
    @observable
    public birthDate: string;
    @observable
    public deliveryConditions: string;
    @observable
    public consignorListingMarket: string;

    @computed
    get consignorLocation() {
        return this.consignorName;
    }
}
