import { makeObservable, observable } from 'mobx';
import FormModel from '../forms/FormModel';

export default class DateSearchModel extends FormModel {
  constructor(year?: number, month?: number) {
    super();
    makeObservable(this);
    !!year ? this.year = year : this.year = -1;
    !!month ? this.month = month : this.month = -1;
  }
  @observable
  public year: number;
  @observable
  public month: number;
}
