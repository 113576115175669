import React from 'react';
import SaleListingModel from 'src/models/SalesListings/saleListingModel';
import { stringIsNullOrEmpty } from 'src/utils/helpers/stringHelpers';
import issuu from 'src/assets/images/issuubutton.png';
import pdfLink from 'src/assets/images/pdfbutton.png';
import videoLink from 'src/assets/images/videosbutton.png';
import websiteLink from 'src/assets/images/webbutton.png';
import infoLink from 'src/assets/images/infobutton.png';
import styled from 'src/utils/styled/styled';
import { siteUrls } from 'src/constants/siteUrls';

const DisplayContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  margin-left: auto;
`;
const StyledImage = styled.img`
  width: 35px;
  height: 35px;
  margin: 1px;
`;

interface IDisplayLinks {
  sale: SaleListingModel;
}
const LinkDisplayIcons = ({ sale }: IDisplayLinks) => {
  return (
    <DisplayContainer>
      {!stringIsNullOrEmpty(sale.customerLinkUrl) && (
        <a target='_blank' href={sale.customerLinkUrl}>
          <StyledImage src={issuu} alt='link' />
        </a>
      )}
      {!stringIsNullOrEmpty(sale.saleCatalogueUrl) && (
        <a target='_blank' href={sale.saleCatalogueUrl}>
          <StyledImage src={pdfLink} alt='link' />
        </a>
      )}

      {!stringIsNullOrEmpty(sale.videoUrl) && (
        <a target='_blank' href={sale.videoUrl}>
          {' '}
          <StyledImage src={videoLink} alt='link' />
        </a>
      )}
      {!stringIsNullOrEmpty(sale.externalSaleLink) && (
        <a target='_blank' href={siteUrls.GetAuctionLotListUrl(sale.auctionId)}>
          <StyledImage src={websiteLink} alt='link' />
        </a>
      )}

      {!stringIsNullOrEmpty(sale.infoDocument) && (
        <a target='_blank' href={`https://dlms.ca/Media/Document/${sale.infoDocument}`}>
          <StyledImage src={infoLink} alt='link' />
        </a>
      )}
    </DisplayContainer>
  );
};

export default LinkDisplayIcons;
