const restoreSettings = () => {
  let settings = null;

  try {
    const storedData = localStorage.getItem('settings');

    if (storedData) {
      settings = JSON.parse(storedData);
    }
  } catch (err) {
    console.error('The stored data is not a stringified JSON structure.');
  }

  return settings;
}

const storeSettings = (settings: any) => {
  localStorage.setItem('settings', JSON.stringify(settings));
}

export {
  restoreSettings,
  storeSettings,
};
