export class LegacyApiUrls {
  public static GetBaseUrl(): string {
    if (window.location.hostname === 'localhost') {
      return 'https://app-test.dlms.ca';
    }
    if (window.location.hostname.indexOf('test') >= 0) {
      return 'https://app-test.dlms.ca'
    }
    return `https://app.dlms.ca`;
  }

  public static Login = `${LegacyApiUrls.GetBaseUrl()}/account/logincontrol`;

  public static LoginCheck = `${LegacyApiUrls.GetBaseUrl()}/account/CheckLogin`;

  public static Logout = `${LegacyApiUrls.GetBaseUrl()}/account/logout`;
}
