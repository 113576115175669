import { makeObservable, observable } from 'mobx';
import FormModel from 'src/models/forms/FormModel';

export default class LotMediaModel extends FormModel {
  constructor() {
    super();
    makeObservable(this);
  }
  @observable
  thumbNail: string;
  @observable
  fileName: string;
}
