import { action, makeObservable, observable } from 'mobx';
import { SetDatePropertyName } from 'src/common/dateHelper';
import AuctionType from 'src/enums/sales/AuctionType';
import FormModel from '../forms/FormModel';

class ExtSaleListingModel extends FormModel {
  constructor() {
    super();
    makeObservable(this);
  }
  
  public SetData(dbData: any, ignoreProps?: string[]): void {
    super.SetData(dbData)
    SetDatePropertyName(dbData, this, 'date');
  }

  @observable
  public id: number;
  @observable
  public auctionId: number;
  @observable
  public date: string;
  @observable
  public description: string;
  @observable
  public name: string;
  @observable
  public infoDocumentOriginalFileName: string;
  @observable
  public infoDocumentSavedFileName: string;
  @observable
  public customerUrl: string;
  @observable
  public customerUrlText: string;
  @observable
  public infoDocumentText: string;
  @observable
  public catalogueUrl: string;
  @observable
  public catalogueUrlText: string;
  @observable
  public videoUrl: string;
  @observable
  public videoUrlText: string;
  @observable
  public saleType: string;
  @observable
  public timedAuctionText: string;
  @observable
  public timedAuctionUrl: string;
  @observable
  public websiteUrl: string;
  @observable
  public websiteText: string;
  @observable
  public auctionType: AuctionType;
  @observable
  public saleTypeOveride: string;
  @observable
  public saleTypeID: number;
}

export default ExtSaleListingModel;
