import styled from 'src/utils/styled/styled';
import { siteColors } from 'src/constants/themes';
import { device } from 'src/constants/themes';
import { IColor } from 'src/interfaces/common/styledComponents';
import { Card } from 'src/components/base/container';

export const ActiveDisplay = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  @media ${device.mobileM} {
    flex-direction: column-reverse;
    gap: 5px;
  }
`;

export const TypeContainer = styled.div<IColor>`
  background-image: linear-gradient(to right, ${(props) => props.color});
  color: white;
  margin-top: -10px;
  width: 200px;
  height: 46px;
  text-align: center;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MobileTypeContainer = styled.div<IColor>`
  background-image: linear-gradient(to right, ${(props) => props.color});
  color: white;
  margin-top: -10px;
  width: 160px;
  height: 36px;
  text-align: center;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;


export const DateContainer = styled.div`
  font-size: 1rem;
  font-weight: 500;
  width: max-content;
  padding-right: 10px;
  @media ${device.mobileM} {
    margin-right: auto;
  }
`;

export const ButtonContainer = styled.div`
  width: 230px;
  max-width: 230px;
  margin-right: 50px;
  position: relative;
  margin-top: 10px;
  @media ${device.tablet} {
    margin-top: 5px;
    margin-bottom: 10px;
  }
`;

export const Badge = styled.blockquote`
  position: absolute;
  top: -22px;
  right: -80px;
  width: max-content;
  background-color: ${siteColors.badge};
  color: white;
  border-radius: 10px;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 1px 6px;
  @media ${device.tablet} {
    left: 100px;
  }
`;

export const LinkCard = styled(Card)`
  cursor: pointer;
  `