import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { LegacyApiUrls } from 'src/constants/legacyApiUrls';
import { useUserStore } from 'src/stores/useMobxStores';
import {
  faChevronDown,
  faUser,
  faGavel,
  faUserEdit,
  faUserTie,
  faUpload,
  faSignOutAlt,
  faHandshake,
  faTv,
  faPenAlt
} from '@fortawesome/free-solid-svg-icons';
import {
  AccountContainer,
  WelcomeContainer,
  Login,
  AccountInformation,
  AccountIcon,
  MobileIcon,
  StyledLink,
  StyledList
} from './styledComponents';
import { ContextualMenu, ContextualMenuItem, IContextualMenuItem, IContextualMenuItemProps } from '@fluentui/react';

interface Menu {
  roles: string[];
  menu: IContextualMenuItem;
}

const roleLink = (relativeHref: string) => {
  return `${LegacyApiUrls.GetBaseUrl()}${relativeHref}`;
};

function LoggedIn() {

  const userStore = useUserStore();

  const linkRef = React.useRef(null);
  const [showContextualMenu, setShowContextualMenu] = React.useState(false);
  const onShowContextualMenu = React.useCallback((ev: React.MouseEvent<HTMLElement>) => {
    ev.preventDefault(); // don't navigate
    setShowContextualMenu(true);
  }, []);
  const onHideContextualMenu = React.useCallback(() => setShowContextualMenu(false), []);
  const [allowedMenu] = React.useState<IContextualMenuItem[]>([]);
  const anchorRef = useRef<HTMLDivElement>();
  const onLogout = () => {
    userStore.Logout();
  };


  const isUserInRoles = () => {
    let exists = false;
    if (userStore.User.roles == null) {
      menuItems.map((value) => allowedMenu.push(value.menu));
    }
    if (!userStore.IsAuthenticated || userStore.User.roles.length === 0) {
      return;
    }
    if (userStore.IsAuthenticated && userStore.User.roles.length === 0) {
      menuItems.map((value) => allowedMenu.push(value.menu));
    }
    menuItems.map(value => {
      exists = false
      userStore.User.roles.map(role => {
        if (value.roles.includes(role) && !exists) {
          allowedMenu.push(value.menu)
          exists = true
        }
      })
    })
  };

  React.useEffect(() => {
    isUserInRoles();
  }, [userStore.User, allowedMenu]);

  const loggedInControl = () => {
    return (
      <>
        <AccountContainer>
          <div ref={anchorRef} style={{ position: 'absolute', top: '80px', right: '25px' }}></div>
          <WelcomeContainer>
            <div>
              <Login>Welcome back,</Login>
              <div>
                {userStore.User.firstName} {userStore.User.lastName}
              </div>
              <AccountInformation>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    cursor: 'pointer',
                    gap: '2px'
                  }}
                  onClick={onShowContextualMenu}>
                  <span>Account Information</span>
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
              </AccountInformation>
            </div>
          </WelcomeContainer>
          <AccountIcon ref={linkRef} onClick={onShowContextualMenu}>
            <FontAwesomeIcon icon={faUser} style={{ fontSize: '1.8rem' }} />
            <MobileIcon>
              <FontAwesomeIcon icon={faChevronDown} />
            </MobileIcon>
          </AccountIcon>
        </AccountContainer>
        <ContextualMenu
          items={allowedMenu}
          hidden={!showContextualMenu}
          target={linkRef}
          isBeakVisible={true}
          onItemClick={onHideContextualMenu}
          onDismiss={onHideContextualMenu}
          styles={{ title: { fontSize: '1rem' } }}
          contextualMenuItemAs={(props: IContextualMenuItemProps) => {
            return props.item.link ? (
              <StyledLink to={props.item.link}>
                <ContextualMenuItem {...props} />
              </StyledLink>
            ) : (
              <StyledList>
                <ContextualMenuItem {...props} />
              </StyledList>
            );
          }}
        />
      </>
    );
  };

  const menuItems: Menu[] = [
    {
      roles: ['Administrator'],
      menu: {
        key: 'Admin (new)',
        text: 'Admin (new)',
        link: '/Streaming/AdImageSetup',
        iconProps: { iconName: 'Share' },
        onRenderIcon: () => {
          return <FontAwesomeIcon icon={faUserTie} />;
        }
      }
    },
    {
      roles: ['Administrator', 'Consignor'],
      menu: {
        key: 'Admin',
        text: 'Admin',
        href: roleLink('/admin/auctionlist.aspx'),
        iconProps: { iconName: 'Share' },
        onRenderIcon: () => {
          return <FontAwesomeIcon icon={faUserTie} />;
        }
      }
    },
    {
      roles: ['Administrator'],
      menu: {
        key: 'Conduct Auction',
        text: 'Conduct Auction',
        href: roleLink('/Auctioneer/default.aspx'),
        iconProps: { iconName: 'Share' },
        onRenderIcon: () => {
          return <FontAwesomeIcon icon={faHandshake} />;
        }
      }
    },
    {
      roles: ['Administrator'],
      menu: {
        key: 'Publish Stream',
        text: 'Publish Stream',
        link: '/Streaming/Publish',
        iconProps: { iconName: 'Share' },
        onRenderIcon: () => {
          return <FontAwesomeIcon icon={faPenAlt} />;
        }
      }
    },
    {
      roles: ['Administrator'],
      menu: {
        key: 'View Stream',
        text: 'View Stream',
        link: '/Streaming/Subscribe',
        iconProps: { iconName: 'Share' },
        onRenderIcon: () => {
          return <FontAwesomeIcon icon={faTv} />;
        }
      }
    },
    {
      roles: ['Administrator', 'Consignor'],
      menu: {
        key: 'Direct Video Upload',
        text: 'Direct Video Upload',
        href: 'https://www.dropbox.com/request/wR75h8WoT1WugJ7aLK5j',
        target: '_blank',
        rel: 'noopener noreferrer',
        iconProps: { iconName: 'Share' },
        onRenderIcon: () => {
          return <FontAwesomeIcon icon={faUpload} />;
        }
      }
    },
    {
      roles: ['Administrator', 'Viewer', 'Buyer', 'Consignor', 'Purebred'],
      menu: {
        key: 'Edit Profile',
        name: 'Edit Profile',
        link: '/Account/Editprofile',
        iconProps: { iconName: 'Share' },
        onRenderIcon: () => {
          return <FontAwesomeIcon icon={faUserEdit} />;
        }
      }
    },
    {
      roles: ['Viewer', 'Buyer', 'Administrator', 'Consignor', 'Purebred'],
      menu: {
        key: 'Buyer Approval',
        name: 'Buyer Approval',
        link: '/Info/BuyerApproval',
        itemProps: { styles: { label: { color: 'green' } } },
        iconProps: { iconName: 'Share' },
        onRenderIcon: () => {
          return <FontAwesomeIcon color={'green'} icon={faGavel} />;
        }
      }
    },
    {
      roles: ['Viewer', 'Buyer', 'Administrator', 'Consignor', 'Purebred'],
      menu: {
        key: 'Log Out',
        text: 'Log Out',
        link: '/',
        onClick: onLogout,
        iconProps: { iconName: 'Share' },
        onRenderIcon: () => {
          return <FontAwesomeIcon icon={faSignOutAlt} />;
        }
      }
    }
  ];

  return loggedInControl();
}

export default observer(LoggedIn);
