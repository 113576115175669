import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from '@fluentui/react/lib/Link';
import { AdModel } from 'src/models/admin/AdModel';
import { stringIsNullOrEmpty } from 'src/utils/helpers/stringHelpers';
import AdService from 'src/services/Admin/AdService';

interface INavAds {
  /**Position the ad will take, use only these three: 2 = Nav1; 3 = Nav2; 4 = Nav3 */
  position: number;
}

const NavAds = ({ position }: INavAds) => {
  const [navList, setNavList] = useState<AdModel>(null);

  useEffect(() => {
    const getNavAds = async () => {
      const service = new AdService();
      const response = await service.getAds(1, position);
      if (response.data.length > 0) {
        setNavList(response.data[0]);
      }
    };
    getNavAds();
  }, [position]);

  const onAdClick = (ad: AdModel) =>{
    var gtag = (window as any).gtag;
    gtag('event', 'ad-click', {
      'dlms_ad_name': ad.name,
      'dlms_ad_url': ad.url
    });
  }

  return (
    navList !== null && (
      <div style={{ objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', textAlign: 'center' }}>
        {!stringIsNullOrEmpty(navList.url) ? (
          <Link onClick={()=>{onAdClick(navList)}} href={navList.url} target='_blank'>
            <img style={{ maxHeight: '15vh' }} src={`data:image/jpeg;base64,${navList.image}`} alt={navList.name} />
          </Link>
        ) : (
          <img style={{ maxHeight: '15vh' }} src={`data:image/jpeg;base64,${navList.image}`} alt={navList.name} />
        )}
      </div>
    )
  );
};

export default observer(NavAds);
