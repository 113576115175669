import React from 'react';
import SaleListingModel from 'src/models/SalesListings/saleListingModel';
import { stringIsNullOrEmpty } from 'src/utils/helpers/stringHelpers';
import issuu from 'src/assets/images/issuubutton.png';
import pdfLink from 'src/assets/images/pdfbutton.png';
import videoLink from 'src/assets/images/videosbutton.png';
import websiteLink from 'src/assets/images/webbutton.png';
import infoLink from 'src/assets/images/infobutton.png';
import styled from 'src/utils/styled/styled';

const DisplayContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  margin-left: auto;
`;
const StyledImage = styled.img`
  width: 35px; height: 35px; margin: 1px;
`;

interface IDisplayLinks {
  sale: SaleListingModel;
}
const DisplayIcons = ({ sale }: IDisplayLinks) => {
  return (
    <DisplayContainer>
      {!stringIsNullOrEmpty(sale.customerLinkUrl) &&  <StyledImage src={issuu} alt='link' />}
      {!stringIsNullOrEmpty(sale.saleCatalogueUrl) && <StyledImage src={pdfLink} alt='link' />}
      {!stringIsNullOrEmpty(sale.videoUrl) && <StyledImage src={videoLink} alt='link' />}
      {!stringIsNullOrEmpty(sale.externalSaleLink) && <StyledImage src={websiteLink} alt='link' />}
      {!stringIsNullOrEmpty(sale.infoDocument) && <StyledImage src={infoLink} alt='link' />}
    </DisplayContainer>
  );
};

export default DisplayIcons;
