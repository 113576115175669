import React from 'react';

export const MarketReportLoader = React.lazy(() => import('src/containers/MarketReport/MarketReport'));
export const MarketDetailLoader = React.lazy(() => import('src/containers/MarketReportDetail/MarketReportDetail'));
export const AuctionLotLoader = React.lazy(() => import('src/containers/AuctionLot/AuctionLotListing'));
export const AuctionLotDetailLoader = React.lazy(() => import('src/containers/AuctionLotDetail/AuctionLotDetail'));
export const ExtSaleListLoader = React.lazy(() => import('src/containers/ExtSaleList/ExtSaleList'));


export const ContactsLoader = React.lazy(() => import('src/containers/Agents/components/Contacts/Contacts'));
export const SupportLoader = React.lazy(() => import('src/containers/Support/Support'));
export const BuyerApprovalLoader = React.lazy(() => import('src/containers/BuyerApproval/BuyerApproval'));
export const AboutUsLoader = React.lazy(() => import('src/containers/AboutUs/AboutUs'));
export const PrivacyPolicyLoader = React.lazy(() => import('src/containers/privacy/privacyPolicy'));
export const TermsOfServiceLoader = React.lazy(() => import('src/containers/terms/termsOfService'));


export const CreateAccountLoader = React.lazy(() => import('src/containers/SignUp/CreateAccount'));
export const EditAccountLoader = React.lazy(() => import('src/containers/Profile/EditProfile'));
export const VerifyAccountLoader = React.lazy(() => import('src/containers/SignUp/Verification/AccountVerification'));
export const RecoverAccountLoader = React.lazy(() => import('src/containers/SignUp/Recovery/AccountRecovery'));
export const ResetPasswordLoader = React.lazy(() => import('src/containers/SignUp/Recovery/ResetPassword'));


export const PublishLoader = React.lazy(() => import('src/containers/streaming/publish'));
export const SubscribeLoader = React.lazy(() => import('src/containers/streaming/subscribe'));
export const VideoIntegrationNewLoader = React.lazy(() => import('src/containers/streaming/integration/videoIntegrationNew'));
export const VideoIntegrationLoader = React.lazy(() => import('src/containers/streaming/integration/videoIntegration'));


export const AddNewAuctionLoader = React.lazy(() => import('src/containers/Admin/NewAuction/NewAuctionMain'))
export const EditAuctionLoader = React.lazy(() => import('src/containers/Admin/EditAuction/EditAuctionMain'))
export const EditLotDetailLoader = React.lazy(() => import('src/containers/Admin/AuctionLot/EditAuctionLotMain'))
export const AddAuctionLotLoader = React.lazy(() => import('src/containers/Admin/AuctionLot/AddAuctionLotMain'))
export const AuctionEmailLoader = React.lazy(() => import('src/containers/Admin/AuctionList/AuctionEmail/AuctionEmail'))
export const AttendeesReportLoader = React.lazy(() => import('src/containers/Admin/AttendeesReport/AttendeesReport'));
export const AuctionListLoader = React.lazy(() => import('src/containers/Admin/AuctionList/AuctionListDisplay/AuctionListDisplay'));
export const AuctionListViewLotLoader = React.lazy(() => import('src/containers/Admin/AuctionList/ViewLots/ViewAuctionLots'));
export const AuctionChatLogLoader = React.lazy(() => import('src/containers/Admin/AuctionList/ChatLogs/AuctionListChatLog'));
export const AuctionTransactionLogLoader = React.lazy(() => import('src/containers/Admin/AuctionList/TransactionLogs/AuctionListTransactionLog'));
export const AuctionReportLoader = React.lazy(() => import('src/containers/Admin/AuctionList/AuctionReport/AuctionReportMain'));
export const ContactInfoLoader = React.lazy(() => import('src/containers/Admin/CompanyInfo/CompanyInfo'));
export const EmailManagerLoader = React.lazy(() => import('src/containers/Admin/EmailManager/EmailManager'));
export const ManageSaleTypeLoader = React.lazy(() => import('src/containers/Admin/ManageSaleType/ManageSaleType'));
export const ManageUsersLoader = React.lazy(() => import('src/containers/Admin/ManageUsers/ManageUsersMain'));
export const ManageUsersEditLoader = React.lazy(() => import('src/containers/Admin/ManageUsers/EditUser/EditUser'))
export const UserLoginReportLoader = React.lazy(() => import('src/containers/Admin/UserLoginReport/UserLoginReport'));
export const ViewChatLogLoader = React.lazy(() => import('src/containers/Admin/ViewChatLog/ViewChatLog'));
export const PureBredBuyersLoader = React.lazy(() => import('src/containers/Admin/PureBredBuyers/PureBredBuyersMain'));
export const ApprovedBuyersLoader = React.lazy(() => import('src/containers/Admin/PureBredBuyers/ApproveBuyer/ApprovedBuyerMain'));
export const ForthcomingAuctionsLoader = React.lazy(() => import('src/containers/Admin/ForthComingAuction/ForthComingAuction'));

export const AdminMainLoader = React.lazy(() => import('src/containers/Admin/AdminMain'));