enum AuctionType {
  None = 0,
  Purebred = 1,
  Presort = 2,
  Feeders = 3,
  Event = 4,
  Timed = 5
}

export default AuctionType;
