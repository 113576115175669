import { action, makeObservable, observable } from 'mobx';

class SpinnerOverlayState {
  
    public constructor() {
      makeObservable(this)
      this.IsOpen = false;
      this.Message = '';
    }
 
  @observable
  public Message: string;

  @observable
  public IsOpen: boolean;

  @action
  public open = (message: string = '') => {
    this.IsOpen = true;
    this.Message = message;
  };

  @action
  public close = () => {
    this.IsOpen = false;
  };
}

export default SpinnerOverlayState;
