import { action, IObservableArray, isObservableArray, makeObservable, observable } from 'mobx';
import { AuctionListDisplayModel } from 'src/models/admin/AuctionListModels/AuctionListDisplayModel';
import { AuctionListSearchModel } from 'src/models/admin/AuctionListModels/AuctionListSearchModel';
import { AudioStreamModel } from 'src/models/admin/AudioStreamModel';
import { CompanyContactModel } from 'src/models/admin/CompanyContactModel';
import { CompanyInfoModel } from 'src/models/admin/CompanyInfoModel';
import { LiveStreamModel } from 'src/models/admin/LiveStreamModel';
import { SaleTypeModel } from 'src/models/admin/SaleTypeModel';
import DateSearchModel from 'src/models/MarketReport/DateSearchModel';
import ExtSaleListingModel from 'src/models/SalesListings/ExtSaleListingModel';
import AuctionInformationService from 'src/services/Admin/AuctionInformationService';
import CompanyContactService from 'src/services/Admin/CompanyContactService';
import CompanyInfoService from 'src/services/Admin/CompanyInfoService';
import SaleTypeService from 'src/services/Admin/SaleTypeService';
import ExtendedSaleListService from 'src/services/Auction/ExtendedSaleListService';




class AdminStore {
  constructor() {
    makeObservable(this);
    this.companyInfo = new Array<CompanyInfoModel>() as IObservableArray<CompanyInfoModel>;
    this.companyInfoPostModel = new CompanyInfoModel();
    this.companyContacts = new Array<CompanyContactModel>() as IObservableArray<CompanyContactModel>;
    this.selectedAuction = new AuctionListDisplayModel();
    this.extSaleList = new Array<ExtSaleListingModel>() as IObservableArray<ExtSaleListingModel>;
    this.saleTypeList = new Array<SaleTypeModel>() as IObservableArray<SaleTypeModel>;
    this.liveStreamList = new Array<LiveStreamModel>() as IObservableArray<LiveStreamModel>;
    this.audioStreamList = new Array<AudioStreamModel>() as IObservableArray<AudioStreamModel>;
    this.isOnlyApprovedBuyers = false;
    this.auctionListSearch = new AuctionListSearchModel();
    this.showLotFeederDetails = false;
    this.showLotPreSortDetails = false;
    this.showLotPureBredDetails = false;
    this.showLotFrame = false;
    this.showLotHerd = false;
    this.marketReportDateSearch = new DateSearchModel();
  }

  @observable
  showLotFeederDetails: boolean;
  @observable
  showLotFrame: boolean;
  @observable
  showLotHerd: boolean;
  @observable
  showLotPreSortDetails: boolean;
  @observable
  showLotPureBredDetails: boolean;
  @observable
  loading: boolean;
  @observable
  marketReportDateSearch: DateSearchModel;
  @observable
  auctionListSearch: AuctionListSearchModel;
  @observable
  companyInfo: IObservableArray<CompanyInfoModel>;
  @observable
  companyInfoPostModel: CompanyInfoModel;
  @observable
  companyContacts: IObservableArray<CompanyContactModel>;
  @observable
  selectedAuction: AuctionListDisplayModel;
  @observable
  extSaleList: IObservableArray<ExtSaleListingModel>;
  @observable
  saleTypeList: IObservableArray<SaleTypeModel>;
  @observable
  liveStreamList: IObservableArray<LiveStreamModel>;
  @observable
  audioStreamList: IObservableArray<AudioStreamModel>;
  @observable
  searchId: number;
  @observable
  isOnlyApprovedBuyers: boolean;

  @action
  TogglePureBred = () => {
    this.showLotPureBredDetails = true;
    this.showLotFeederDetails = false;
    this.showLotPreSortDetails = false;
  };
  @action
  ToggleFeeders = () => {
    this.showLotPureBredDetails = false;
    this.showLotFeederDetails = true;
    this.showLotPreSortDetails = false;
  };
  @action
  TogglePresort = () => {
    this.showLotPureBredDetails = false;
    this.showLotFeederDetails = false;
    this.showLotPreSortDetails = true;
  };
  @action
  ResetSearchOnChangeSaleStatus = () => {
    this.auctionListSearch.buyer = null;
    this.auctionListSearch.auctionTime = null;
    this.auctionListSearch.month = new Date().getMonth();
    this.auctionListSearch.year = new Date().getFullYear();;
    this.auctionListSearch.consignorName = null;
    this.auctionListSearch.fieldManAgent = null;
    this.auctionListSearch.saleType = -1;
  };

  getCompanyInfo = async () => {
    const service = new CompanyInfoService();
    await service.getCompanysInfo(this);
  };
  updateCompanyInfo = async (company: CompanyInfoModel) => {
    const service = new CompanyInfoService();
    return await service.updateCompanyInfo(company);
  };
  createCompanyInfo = async (company: CompanyInfoModel) => {
    const service = new CompanyInfoService();
    return await service.createCompanyInfo(company);
  };
  getCompanyContacts = async () => {
    const service = new CompanyContactService();
    await service.getContactsInfo(this);
  };
  updateCompanyContacts = async (contact: CompanyContactModel) => {
    const service = new CompanyContactService();
    return await service.updateContactInfo(contact);
  };
  createCompanyContacts = async (contact: CompanyContactModel) => {
    const service = new CompanyContactService();
    return await service.createContactInfo(contact);
  };
  deleteCompanyContacts = async (id: number) => {
    const service = new CompanyContactService();
    await service.deleteContactInfo(id);
    await this.getCompanyContacts();
  };
  getExtendedSaleListing = async () => {
    const service = new ExtendedSaleListService();
    return await service.getExtSalesListing(this);
  };
  updateExtendedSaleListing = async (model: ExtSaleListingModel) => {
    const service = new ExtendedSaleListService();
    return await service.updateExtSaleListing(model);
  };
  createExtendedSaleListing = async (model: ExtSaleListingModel) => {
    const service = new ExtendedSaleListService();
    return await service.createExtSaleListing(model);
  };
  deleteSaleListing = async (id: number) => {
    const service = new ExtendedSaleListService();
    await service.deleteExtSaleListing(id);
    await this.getExtendedSaleListing();
  };
  getSaleTypeList = async () => {
    const service = new SaleTypeService();
    return await service.getSaleTypeInfo(this);
  };
  getHostMarkets = async () => {
    const service = new AuctionInformationService();
    return await service.getHostMarkets(this);
  };
  getAudioStreams = async () => {
    const service = new AuctionInformationService();
    return await service.getAudioStreams(this);
  };
}

export default AdminStore;
