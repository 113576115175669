import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FlexContainer } from 'src/components/base/container';
import { useUserStore, useUtilityStore } from 'src/stores/useMobxStores';
import logo from '../../../assets/images/DLMS-Logo-Vector-2021-250x80.png';
import { ADMIN_NAV_ITEMS } from '../../../constants/NavConstants/AdminNavBarConstants';
import { AdminbarItem } from './NavItems/AdminbarItem';
import {
  AdminDrawerContainer,
  AdminNavBox,
  Divider,
  ButtonContainer,
  CloseButton,
  AdminDrawer,
  AdminLogo
} from './styledComponents';
import { List } from 'src/components/base/list';
import { HiddenDesktopDown, HiddenDesktopUp } from 'src/components/base/hidden';


const AdminNavbar: React.FC = () => {
  const userStore = useUserStore();
  const utilityStore = useUtilityStore();

  const content = (
    <AdminDrawerContainer>
      <FlexContainer justifyContent='center'>
        <RouterLink to='/'>
          <AdminLogo onClick={utilityStore.closeDrawer} src={logo} alt='logo' />
        </RouterLink>
      </FlexContainer>
      <Divider />
      <AdminNavBox>
        <List>
          {ADMIN_NAV_ITEMS.map((item, itemIndex) => (
            <AdminbarItem handleNavbarClose={utilityStore.closeDrawer} key={itemIndex} item={item} />
          ))}
        </List>
        <ButtonContainer>
          <HiddenDesktopUp>
            <CloseButton onClick={utilityStore.closeDrawer}>Close</CloseButton>
          </HiddenDesktopUp>
        </ButtonContainer>
      </AdminNavBox>
    </AdminDrawerContainer>
  );

  return (
    <>
      <HiddenDesktopDown>
        <AdminDrawer>{content}</AdminDrawer>
      </HiddenDesktopDown>
    </>
  );
};

export default observer(AdminNavbar);
