export class siteUrls {
  public static GetAuctionLotDetailUrl(auctionId: number, lotId: number): string {
    return `/Report/Lot?auctionId=${auctionId}&lotId=${lotId}`;
  }
  public static GetAuctionLotListUrl(id: number): string {
    return `/Report/AuctionLot?AuctionId=${id}`;
  }
  public static GetMarketReportDetailUrl(id: number): string {
    return `/Report/MarketDetail?auctionId=${id}`;
  }
  public static GetDlmsPromoImageUrl(id: string): string {
    return `https://www.dlms.ca/Media/PromoImage/${id}`;
  }
  public static GetPrintToPdfModeSoldUrl(id: number): string {
    return `/AuctionInfo/PrintToPDF?AuctionId=${id}&mode=sold`;
  }
  public static GetPrintToPdfLandScapeModeSoldUrl(id: number): string {
    return `/AuctionInfo/PrintToPDF?orientation=landscape&AuctionId=${id}&mode=sold`;
  }

  public static HomeUrl = '/';
  public static AboutUsUrl = '/Info/AboutUs';
  public static AgentRepsUrl = '/Info/AgentReps';
  public static ListingMarketsUrl = '/Info/Markets';
  public static MarketReportUrl = '/Report/MarketReport';
  public static ExtSalesListUrl = '/Report/ExtSalesList';
  public static SupportUrl = '/Info/Support';
  public static PrivacyPolicyUrl = '/Info/PrivacyPolicy';
  public static TermsOfServiceUrl = '/Info/TermsOfService';
  public static BuyerApprovalUrl = '/Info/BuyerApproval';
  public static ContactInfoUrl = `/Admin/ContactInfo`;
  public static AuctionListUrl = `/Admin/AuctionList`;
  public static AddNewAuctionUrl = `/Admin/AddNewAuction`;
  public static ExtSaleListingUrl = `/Admin/ForthcomingAuctions`;
  public static AttendeesReportUrl = `/Admin/AttendeesReport`;
  public static ManageUsersUrl = `/Admin/ManageUsers`;
  public static PureBredBuyersUrl = `/Admin/BuyerApproval`;
  public static ManageSaleTypeUrl = `/Admin/ManageSaleType`;
  public static EmailManagerUrl = `/Admin/EmailManager`;
  public static UserLoginReportUrl = `/Admin/UserLoginReport`;
  public static AdImageSetupUrl = `/Admin/AdImageSetup`;
  public static ViewChatLogUrl = `/Admin/ViewChatLog`;
  public static Streaming = '/Streaming';
}