import { throws } from 'assert';
import { makeObservable, observable } from 'mobx';
import FormModel from 'src/models/forms/FormModel';

export default class HerdMakeUpModel extends FormModel {
  constructor() {
    super();
    makeObservable(this);
    
  }

  @observable
  lotId: number;
  @observable
  breedDescription: string;
  @observable
  percentage: number;
  @observable
  herdId: number;
}
