import { makeObservable, observable } from 'mobx';
import { ISaleStatus } from 'src/interfaces/Admin/ISaleStatus';
import DateSearchModel from 'src/models/MarketReport/DateSearchModel';

export class AuctionListSearchModel extends DateSearchModel implements ISaleStatus{
  constructor(saleStatus?: string) {
    super();
    makeObservable(this);
    this.saleStatus = saleStatus ? saleStatus : 'CONSIG';
    this.year = saleStatus == 'SOLD' ? new Date().getFullYear() : null;
    this.month = saleStatus == 'SOLD' ? new Date().getMonth() : null;
  }
  @observable
  public saleStatus: string;
  @observable
  public saleType: number;
  @observable
  public consignorName: string;
  @observable
  public fieldManAgent: string;
  @observable
  public auctionTime: string;
  @observable
  public buyer: string;
}
