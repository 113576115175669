import { computed, IObservableArray, makeObservable, observable } from 'mobx';
import { formatFullDate } from 'src/common/formatters';
import FormModel from 'src/models/forms/FormModel';
import { arraySetData } from 'src/utils/helpers/arrayHelpers';
import { stringIsNullOrEmpty } from 'src/utils/helpers/stringHelpers';
import AuctionListDetailModel from './AuctionListDetailModel';

export default class AuctionLotListModel extends FormModel {
  constructor() {
    super();
    makeObservable(this);
    this.lotDetails = new Array<AuctionListDetailModel>() as IObservableArray;
  }

  public SetData(dbData: any): void {
    super.SetData(dbData, ['lotDetails']);
    arraySetData(this.lotDetails, AuctionListDetailModel, dbData.lotDetails);
  }
  @observable
  public auctionId: number;
  @observable
  public auctionName: string;
  @observable
  public auctionDate: string;
  @observable
  public auctionType: string;
  @observable
  public auctionTypeEnum: number;
  @observable
  public auctionTimeZone: string;
  @observable
  public auctionDescription: string;
  @observable
  public promotionalImage: string;
  @observable
  public customerLinkText: string;
  @observable
  public customerLinkUrl: string;
  @observable
  public infoDocument: string;
  @observable
  public infoDocumentText: string;
  @observable
  public lotDetails: IObservableArray<AuctionListDetailModel>;
  @observable
  public saleCatalogueText: string;
  @observable
  public saleCatalogueUrl: string;
  @observable
  public videoUrl: string;
  @observable
  public videoText: string;
  @observable
  public promoImage: string;
  @observable
  public auctionTypeDisplay: string;

  @computed
  public get DisplayAuctionType() {
    return !stringIsNullOrEmpty(this.auctionTypeDisplay) ? this.auctionTypeDisplay : this.auctionType
  }

  @computed
  public get getAuctionDate() {
    return formatFullDate(this.auctionDate);
  }
}
