import React from 'react';
import { Link } from 'react-router-dom';
import { FlexContainer, SmallPaddedBox } from 'src/components/base/container';
import { device } from 'src/constants/themes';
import styled from 'src/utils/styled/styled';
import { FOOTER_ITEMS } from '../../../constants/NavConstants/FooterConstants';


const FooterBox = styled.div`
  border-top: 1px solid rgb(10, 10, 10, 0.1);
  height: 80px;
  padding-top: 10px;
  @media ${device.mobileM} {
    display: none
  }
`;

function Footer() {
  
  const year = new Date().getFullYear();
  return (
    <FooterBox>
      <SmallPaddedBox>
      <FlexContainer justifyContent='center'>
          {FOOTER_ITEMS.map((item, itemIndex) => (
            <FlexContainer justifyContent='center' key={itemIndex}>
              <Link to={item.link} style={{color: 'black'}}>
                {item.title}
              </Link>
            </FlexContainer>
          ))}
        </FlexContainer>
        </SmallPaddedBox>
        <div style={{ textAlign: 'center' }}> Copyright ©{year} Genesys Networks Inc. All rights reserved.</div>
      </FooterBox>
  );
}

export default Footer;
