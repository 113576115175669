import { observer } from 'mobx-react-lite';
import React from 'react';
import LoggedIn from './Login/loggedin';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Login from './Login/login';
import {  LoadingBox, LoginButton, BarContainer, MenuBox, LoginBox, HeaderName, HeaderH1, Grow } from './styledComponents';
import { useUserStore, useUtilityStore } from 'src/stores/useMobxStores';
import SignInModal from '../LoginModal/Login/SignInModal';

import { Spinner, SpinnerSize } from '@fluentui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HiddenTabletDown, HiddenTabletUp } from 'src/components/base/hidden';


function Header() {
  const userStore = useUserStore();
  const utilityStore = useUtilityStore();
  const headerBanner = (
    <HeaderName>
      <HeaderH1>
        <Grow>D</Grow>irect <Grow>L</Grow>ivestock <Grow>M</Grow>arketing <Grow>S</Grow>ystems
      </HeaderH1>
    </HeaderName>
  );

  const loadingSpinner = () => (
    <LoadingBox>
      <Spinner
        styles={{
          circle: { borderWidth: '2px', borderColor: 'rgba(255, 255, 255, 0) rgb(250, 250, 250) rgb(245, 246, 247)' }
        }}
        size={SpinnerSize.large}
      />
    </LoadingBox>
  );

  const mobileLogin = () =>
    userStore.IsAuthenticated ? (
      <LoggedIn />
    ) : (
      <LoginBox>
        <LoginButton onClick={() => utilityStore.openModal(<SignInModal />)}>Login / Sign Up</LoginButton>
      </LoginBox>
    );
  const desktopLogin = () =>
    userStore.IsAuthenticated ? <LoggedIn /> : <div>{userStore.IsAuthenticated ? <LoggedIn /> : <Login />}</div>;

  return (
    <header style={{ position: 'fixed', height: '80px', width: '100%', zIndex: 1000 }}>
      <BarContainer>
        <MenuBox>
          <FontAwesomeIcon icon={faBars} style={{ fontSize: '1.4rem' }} onClick={utilityStore.openDrawer} />
        </MenuBox>
        {headerBanner}
        <LoginBox>
          <HiddenTabletUp>{userStore.loading ? loadingSpinner() : mobileLogin()}</HiddenTabletUp>
        </LoginBox>
        <LoginBox>
          <HiddenTabletDown>{userStore.loading ? loadingSpinner() : desktopLogin()}</HiddenTabletDown>
        </LoginBox>
      </BarContainer>
    </header>
  );
}

export default observer(Header);
