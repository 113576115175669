import React from 'react';
import { FlexContainer } from 'src/components/base/container';
import { EmphasizedTextDisplay } from 'src/components/base/styledComponents';
import AuctionButtons from 'src/components/common/AuctionButtons/AuctionButtons';
import SignInModal from 'src/components/common/LoginModal/Login/SignInModal';
import AuctionType from 'src/enums/sales/AuctionType';
import SaleListingModel from 'src/models/SalesListings/saleListingModel';
import { useUtilityStore } from 'src/stores/useMobxStores';
import { ButtonContainer, Badge } from './styledcomponents';

interface IActiveAuctionComponents {
  sale: SaleListingModel;
}

export const ActiveAuctionDescription: React.FC<IActiveAuctionComponents> = ({ sale }: IActiveAuctionComponents) => {
  return (
    <FlexContainer column gap={'4px'}>
      <EmphasizedTextDisplay>{sale.auctionName}</EmphasizedTextDisplay>
      <EmphasizedTextDisplay>
        {sale.auctionType !== AuctionType.Timed && `Live From ${sale.auctionMarket}`}
      </EmphasizedTextDisplay>
    </FlexContainer>
  );
};

export const ActiveAuctionButton: React.FC<IActiveAuctionComponents> = ({ sale }: IActiveAuctionComponents) => {
   const utilityStore = useUtilityStore();
  const handleSignIn = () => {
    utilityStore.openModal(<SignInModal />);
  };
  return (
    <>
      {sale.auctionType === AuctionType.Timed ? (
        <ButtonContainer>
          <Badge>BIDDING OPEN</Badge>
          <AuctionButtons sale={sale} onLogInModal={handleSignIn} />
        </ButtonContainer>
      ) : (
        <ButtonContainer>
          <AuctionButtons sale={sale} onLogInModal={handleSignIn} />
        </ButtonContainer>
      )}
    </>
  );
};
