import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormProvider, useForm, ValidationMode } from 'react-hook-form';
import { Form } from 'reactstrap';

export interface IFormWrapper {
  onSubmit?: (data: any) => void;
  children?: React.ReactNode;
  mode?: keyof ValidationMode;
}

const FormWrapper = (props: IFormWrapper) => {
  const { onSubmit, children, mode } = props;
  const form = useForm({ mode });
  const onSubmitCallback = React.useCallback(
    (data) => {
      if (onSubmit) {
        onSubmit(data);
      }
    },
    [onSubmit]
  );

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmitCallback)}>{children}</Form>
    </FormProvider>
  );
};

export default observer(FormWrapper);
