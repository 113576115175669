import { observer } from 'mobx-react-lite';
import React from 'react';
import { TimesNewPageTitle } from 'src/components/base/styledComponents';
import SaleListingModel from 'src/models/SalesListings/saleListingModel';
import ActiveAuctionDisplay from './ActiveAuctionDisplay';

interface IActiveAuctionsList {
  rows: Array<SaleListingModel>;
  title: string;
  isTimedSale: boolean;
}

const ActiveAuctionsList: React.FC<IActiveAuctionsList> = ({ rows, title, isTimedSale }: IActiveAuctionsList) => {
  if (rows.length === 0 && isTimedSale) {
    return null;
  }
  let noEventsText = isTimedSale
    ? 'No timed sales currently in progress'
    : 'No Live sales or events currently in progress';
  return (
    <>
      <TimesNewPageTitle isBlack>{title}</TimesNewPageTitle>

      {rows.length === 0 ? (
        <h3 style={{marginLeft: 30}}>{noEventsText}</h3>
      ) : (
        rows.map((row, index) => {
          return <ActiveAuctionDisplay sale={row} key={index} />;
        })
      )}
    </>
  );
}

export default observer(ActiveAuctionsList);
